<template>
  <div class="page-content">
    <div class="row chat-wrapper" style="height: auto !important">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body">
            <div class="row position-relative">
              <div class="col-lg-4 chat-aside border-lg-right">
                <div class="aside-content">
                  <div class="aside-header">
                    <form class="search-form form-group">
                      <div class="row">
                        <div class="col-sm-8">
                          <div class="input-group border rounded-sm" v-if="searchForm.search_by !== 'label'">
                            <input @keyup="handlerSearchInput" v-model="searchForm.search" type="text" class="form-control-sm border-0 rounded-sm" id="searchForm" placeholder="Search here...">
                          </div>
                          <el-select @clear="loadConversations" clearable filterable @change="handlerFilterLabelChange" placeholder="Choose label" class="w-100" size="small" v-model="searchForm.label_id" v-else>
                            <el-option v-for="label in labelList"
                              :key="label.id"
                              :label="label.title"
                              :value="label.id"
                              >{{ label.title }}</el-option>
                          </el-select>
                        </div>
                        <div class="col-sm-4">
                          <div class="input-group">
                            <el-select
                              size="mini"
                              @change="searchByChanged"
                              v-model="searchForm.search_by">
                              <el-option :key="i" v-for="(item, i) in search_by_list" :label="item.text" :value="item.value"></el-option>
                            </el-select>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-6">
                          <el-select
                            class="mt-2"
                            size="mini"
                            @change="loadConversations"
                            v-model="searchForm.assigned_to"
                            >
                            <el-option :key="i" v-for="(item, i) in assigned_to_list" :label="item.text" :value="item.value"></el-option>
                          </el-select>
                        </div>
                        <div class="col-sm-6">
                          <el-select
                            class="mt-2"
                            size="mini"
                            @change="loadConversations"
                            v-model="searchForm.sort_by"
                            >
                            <el-option :key="i" v-for="(item, i) in sort_by_list" :label="item.text" :value="item.value"></el-option>
                          </el-select>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="aside-body">
                    <ul class="nav nav-tabs mt-3" ref="tabStatusConvo" id="tabStatusConvo" role="tablist" v-if="searchForm.assigned_to !== 'bot'">
                      <li class="nav-item">
                        <a @click="tabStatusConvoClicked('open')" :class="{ 'active': selected_conversation_status == 'open' }" class="nav-link px-2" ref="open_status_tab" id="open" data-toggle="tab" href="#open" role="tab" aria-controls="open" aria-selected="true">
                          <div class="d-flex flex-row flex-lg-column flex-xl-row align-items-center">
                            <p id="open-title">Open</p>
                            <span style="padding: 6px" class="badge badge-danger ml-1">
                              <!-- <font-awesome icon="inbox"/> -->
                              {{ status_count.open }}
                            </span>
                          </div>
                        </a>
                      </li>
                      <li class="nav-item">
                        <a @click="tabStatusConvoClicked('handling')" :class="{ 'active': selected_conversation_status == 'handling' }" class="nav-link px-2" ref="handling_status_tab" id="handling" data-toggle="tab" href="#handling" role="tab" aria-controls="handling" aria-selected="false">
                          <div class="d-flex flex-row flex-lg-column flex-xl-row align-items-center">
                            <p id="handling-title">Handling</p>
                            <span style="padding: 6px" class="badge badge-primary ml-1">
                              <!-- <font-awesome icon="chalkboard-teacher"></font-awesome>&nbsp; -->
                              {{ status_count.handling }}
                            </span>
                          </div>
                        </a>
                      </li>
                      <li class="nav-item">
                        <a @click="tabStatusConvoClicked('resolved')" :class="{ 'active': selected_conversation_status == 'resolved' }" class="nav-link px-2" ref="resolved_status_tab" id="resolved" data-toggle="tab" href="#resolved" role="tab" aria-controls="resolved" aria-selected="false">
                          <div class="d-flex flex-row flex-lg-column flex-xl-row align-items-center">
                            <p id="resolved-title">Resolved</p>
                            <span style="padding: 6px" class="badge badge-success ml-1">
                              <!-- <font-awesome icon="clipboard-check"></font-awesome>&nbsp; -->
                              {{ status_count.resolved }}
                            </span>
                          </div>
                        </a>
                      </li>
                    </ul>
                    <ul class="nav nav-tabs mt-3" ref="tabStatusConvo" id="tabStatusConvo" role="tablist" v-if="searchForm.assigned_to == 'bot'">
                      <li class="nav-item">
                        <a class="nav-link active" ref="open_status_tab" id="open" data-toggle="tab" href="#open" role="tab" aria-controls="open" aria-selected="true">
                          <div class="d-flex flex-row flex-lg-column flex-xl-row align-items-center">
                            <p id="open-title">Unhandled</p>
                            <!-- <span class="badge badge-pill badge-primary ml-1">3</span> -->
                          </div>
                        </a>
                      </li>
                    </ul>
                    <div class="tab-content mt-3" v-loading="loading.chat_list" style="min-height: 400px; height: auto;" @scroll="onScrollListConversations">
                      <div class="tab-pane fade show active chat-list" ref="status_open" id="open" role="tabpanel" aria-labelledby="open">
                        <div v-if="conversations.length === 0">
                          <el-empty :image="noInbox" description="No conversations"></el-empty>
                        </div>
                        <div v-else>
                          <ul class="list-unstyled chat-list px-1">
                            <li
                              class="chat-item pr-1"
                              @click="pagination.messages = 1; showDetailChat({ conversation_id: conversation.id });"
                              v-for="(conversation, i) in conversations"
                              :key="i">
                              <a href="javascript:;" class="d-flex align-items-center">
                                <!-- <figure class="mb-0 mr-2">
                                  <img src="https://via.placeholder.com/37x37" class="img-xs rounded-circle" alt="user">
                                </figure> -->
                                <div v-if="searchForm.assigned_to === 'bot'" :style="{ '--initial-ticket-color': conversation.thumbnail_color }" data-initials-ticket="-"/>
                                <div v-else class="identicons" v-html="identicons(conversation.id)"/>
                                <div class="flex-grow border-bottom">
                                  <div class="d-flex justify-content-between align-items-center">
                                    <div class="d-flex flex-column mb-2">
                                      <p class="font-weight-bold" :class="{ 'text-body': true, ...isNearExpired(conversation.difference_last_msg_in) }">{{ searchForm.assigned_to !== 'bot' ? '#' + conversation.code : (conversation.contacts[0].display_name || conversation.contacts[0].name) }}</p>
                                      <p v-if="searchForm.assigned_to !== 'bot'" class="font-weight-bold mr-1" :class="{ 'text-warning': false, 'text-muted': false, 'text-body': true  }">{{ truncate(conversation.contacts[0].display_name || conversation.contacts[0].name, { length: 30 }) }}</p>
                                    </div>
                                    <!-- <p class="font-weight-bold" :class="{ 'text-body': true, ...isNearExpired(conversation.difference_last_msg_in) }">{{ searchForm.assigned_to !== 'bot' ? '#' + conversation.code : conversation.contacts[0].display_name }}</p> -->
                                    <!-- <p class="font-weight-bold mr-1" :class="{ 'text-warning': false, 'text-muted': false, 'text-body': true  }">{{ truncate(conversation.contacts.[0].name +' aiushdiuahsdiuhasduh dkfljgdfg', { length: 26 }) }}</p>
                                    <p class="font-weight-normal tx-14" :class="{ 'text-body': true, ...isNearExpired(conversation.difference_last_msg_in) }">{{ searchForm.assigned_to !== 'bot' ? '#' + conversation.code : conversation.contacts[0].display_name }}</p> -->
                                    <p class="text-muted tx-13 mb-1 text-right">
                                      <span v-if="conversation.agent && conversation.agent.user" class="text-muted">(Agent: {{ conversation.agent.user.name }})<br/></span>
                                      {{ moment(conversation.last_msg.created_at).fromNow() }}
                                      <a @click="pinChat(conversation.pin_at)" v-if="['open', 'handling'].includes(conversation.status) && conversation.pin_at" class="btn btn-icon pull-right text-success" title="Pin Chat">
                                        <i class="mdi mdi-pin"></i>
                                      </a>
                                    </p>
                                  </div>
                                  <div>
                                    <p class="tx-13 preview-chat" :class="{ 'text-muted': conversation.last_msg.type == 'out', 'inbound-text': conversation.last_msg.type == 'in', 'font-weight-bold': conversation.last_msg.type == 'in' }">
                                      <i :class="{ 'status-read': conversation.last_msg.status === 'read', 'mdi-check-all': ['sent', 'delivered', 'read'].includes(conversation.last_msg.status), 'mdi-clock': conversation.last_msg.status == 'pending', 'mdi-close-circle-outline': conversation.last_msg.status === 'failed', 'text-danger': conversation.last_msg.status === 'failed' }" v-if="conversation.last_msg.type == 'out'" class="mdi"/>
                                      <span v-if="['text', 'reaction', 'system', 'private_note', 'interactive', 'template'].includes(conversation.last_msg.body_type)">
                                        <i v-if="conversation.last_msg.status == 'deleted'">{{ conversation.last_msg.body }}</i>
                                        <span v-else>{{ conversation.last_msg.interactive_type == 'call_permission_reply' ? '(' + upperFirst(conversation.last_msg.interactive_action?.response) + ' call request)' : parseHtmlAnchor(conversation.last_msg.body) }}</span>
                                      </span>
                                      <span v-else-if="conversation.last_msg.body_type == 'image'">
                                        <FeatherIcon type="image" size="15px" class="mt-2" />
                                        {{ parseHtmlAnchor(conversation.last_msg.caption) || $t('general.image') }}
                                      </span>
                                      <span v-else-if="conversation.last_msg.body_type == 'video'">
                                        <FeatherIcon type="video" size="15px" class="mt-2" />
                                        {{ parseHtmlAnchor(conversation.last_msg.caption) || $t('general.video') }}
                                      </span>
                                      <span v-else-if="conversation.last_msg.body_type == 'document'">
                                        <FeatherIcon type="paperclip" size="15px" class="mt-2" />
                                        {{ parseHtmlAnchor(conversation.last_msg.caption) || $t('general.document') }}
                                      </span>
                                      <span v-else-if="conversation.last_msg.body_type == 'contacts'">
                                        <FeatherIcon type="user" size="15px" class="mt-2" />
                                        {{ parseHtmlAnchor(conversation.last_msg.caption) }}
                                      </span>
                                      <span v-else-if="conversation.last_msg.body_type == 'location'">
                                        <FeatherIcon type="map-pin" size="15px" class="mt-2" /> {{ $t('general.location') }}
                                      </span>
                                      <span v-else-if="conversation.last_msg.body_type == 'audio' || conversation.last_msg.body_type == 'voice'">
                                        <FeatherIcon type="volume-2" size="15px" class="mt-2" /> {{ $t('general.audio') }}
                                      </span>
                                      <span v-else-if="conversation.last_msg.body_type == 'sticker'">
                                        <FeatherIcon type="smile" size="15px" class="mt-2" /> (Sticker)
                                      </span>
                                      <span v-else-if="conversation.last_msg.body_type == 'call'">
                                        <FeatherIcon type="phone-call" size="15px" class="mt-2" /> (Voice Call)
                                      </span>
                                      <span v-else-if="conversation.last_msg.body_type.includes('story_mention')">
                                        <FeatherIcon :type="conversation.last_msg.body_type.replace('story_mention_', '')" size="15px" class="mt-2" /><i>Mentioned you at their story</i>
                                      </span>
                                      <span v-else-if="conversation.last_msg.body_type.includes('story_reply')">
                                        Replied to your story: {{ conversation.last_msg.caption }}
                                      </span>
                                    </p>
                                  </div>
                                  <div style="gap: 2px">
                                    <el-tag class="mr-1 mt-1 mb-1" size="mini" :style="{ 'background-color': label.color, color: '#fff' }" v-for="label in conversation.labels.slice(0,3)" :key="label.id">{{ label.title }}</el-tag>
                                    <small class="text-black" v-if="conversation.labels.length > 3">+{{ conversation.labels.length - 3 }} more</small>
                                  </div>
                                </div>
                              </a>
                            </li>
                          </ul>
                          <div v-loading="loading.load_more" v-if="isMoreResult" align="center">
                            <!-- <a @click="infiniteHandler">Load more</a> -->
                            <span></span>
                          </div>
                          <div v-if="!isMoreResult" align="center">
                            <p>No more conversations 🎉 🎉</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="chat-content" :class="{ 'show' : showDetail, 'col-lg-5': showDetailConversation, 'col-lg-8': !showDetailConversation }" v-show="loadedConversation" v-loading="loading.chat_detail">
                <div class="chat-header border-bottom pb-2">
                  <div class="d-flex justify-content-between">
                    <div class="d-flex align-items-center" v-if="!['unhandled'].includes(conversation.status)">
                      <i data-feather="corner-up-left" id="backToChatList" class="icon-lg mr-2 ml-n2 text-muted d-lg-none"></i>
                      <div class="identicons" v-html="identicons(conversation.id)"/>
                      <div>
                        <p><b>#{{ conversation.code }}</b></p>
                        <p class="text-muted tx-13">{{ conversation.contactName }}, {{ conversation.phoneNumber }}</p>
                        <el-tag class="mr-1 mt-1 mb-1" size="mini" :style="{ 'background-color': label.color, color: '#fff' }" v-for="label in conversation.labels" :key="label.id">{{ label.title }}</el-tag>
                      </div>
                    </div>
                    <div class="d-flex align-items-center" v-else>
                      <div :data-initials-ticket="'-'"/>
                      <div>
                        <p><b>{{ conversation.contactName }}</b></p>
                        <p class="text-muted tx-13">{{ conversation.phoneNumber }}</p>
                        <el-tag class="mr-1 mt-1 mb-1" size="mini" :style="{ 'background-color': label.color, color: '#fff' }" v-for="label in conversation.labels" :key="label.id">{{ label.title }}</el-tag>
                      </div>
                    </div>
                    <div class="d-flex align-items-center mr-n1">
                      <b-dropdown v-if="(isEnableCallFeature || isEnableVoIPFeature) && conversation.status != 'resolved' && ['whatsapp'].includes(conversation.active_channel)" variant="link" toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                          <i class="mdi mdi-phone mdi-24px"></i>
                        </template>
                        <b-dropdown-item href="#" :disabled="!isAgent" v-if="isEnableCallFeature && (!conversation.call_request_status || conversation.call_request_status == 'reject')" @click="handleInitRequestCall()">Request Whatsapp call permission</b-dropdown-item>
                        <b-dropdown-item href="#" :disabled="true" v-else-if="isEnableCallFeature">Request Call via Whatsapp</b-dropdown-item>
                        <b-dropdown-item href="#" :disabled="!isAgent" v-if="isEnableCallFeature && conversation.call_request_status == 'accept'" @click="doCall()">Call via Whatsapp</b-dropdown-item>
                        <b-dropdown-item href="#" :disabled="!isAgent || !buttonCallActive" v-if="isEnableVoIPFeature" @click="handleCall()">Call via Mobile</b-dropdown-item>
                      </b-dropdown>
                      <!-- <el-tooltip v-if="isAgent && isEnableCallFeature && ['open', 'handling', 'undhandled'].includes(conversation.status) && ['whatsapp'].includes(this.conversation.active_channel)" class="item" effect="dark" content="Call User" placement="bottom">
                        <a :class="`btn btn-icon rounded-circle ${buttonCallStyle}`" title="Call User" @click="handleCall()">
                          <i class="mdi mdi-phone mdi-24px"></i>
                        </a>
                      </el-tooltip>
                      <el-tooltip v-if="isEnableCallFeature && conversation.status != 'resolved' && ['whatsapp'].includes(conversation.active_channel) && (!conversation.call_request_status || conversation.call_request_status == 'reject')" class="item" effect="dark" content="Request call permission" placement="bottom">
                        <a :class="`btn btn-icon rounded-circle ${buttonCallStyle}`" title="Call" @click="handleInitRequestCall()">
                          <FeatherIcon type="phone-outgoing" />
                        </a>
                      </el-tooltip>
                      <el-tooltip v-if="isEnableCallFeature && conversation.status != 'resolved' && ['whatsapp'].includes(conversation.active_channel) && conversation.call_request_status == 'accept'" class="item" effect="dark" content="Call" placement="bottom">
                        <a :class="`btn text-success btn-icon rounded-circle ${buttonCallStyle}`" title="Call" @click="doCall()">
                          <FeatherIcon type="phone-outgoing" />
                        </a>
                      </el-tooltip> -->
                      <el-tooltip v-if="['open', 'handling'].includes(conversation.status) && (rows_pin[conversation.status] < 3 || conversation.pin_at)" class="item" effect="dark" content="Pin Chat" placement="bottom">
                        <a class="btn btn-icon rounded-circle" :class="conversation.pin_at ? 'text-success' : 'text-black'" title="Pin Chat" @click="pinChat(conversation.pin_at)">
                          <i class="mdi mdi-pin mdi-24px"></i>
                        </a>
                      </el-tooltip>
                      <el-tooltip v-if="['open', 'handling', 'resolved'].includes(conversation.status) && this.steps_list.length > 0" class="item" effect="dark" content="Leads" placement="bottom">
                        <a class="btn btn-icon rounded-circle text-black" title="Add to leads" @click="() => { showModalCreateLeads = true; }">
                          <i class="mdi mdi-animation"></i>
                        </a>
                      </el-tooltip>
                      <el-tooltip v-if="conversation.status == 'unhandled'" class="item" effect="dark" content="Take over conversation" placement="bottom">
                        <a class="btn btn-icon rounded-circle" @click="handlerTakeOverBtn">
                          <font-awesome :icon="['far', 'handshake']"/>
                        </a>
                      </el-tooltip>
                      <el-tooltip v-if="!['unhandled', 'resolved'].includes(conversation.status)" class="item" effect="dark" content="Mark as resolved" placement="bottom">
                        <a class="btn btn-icon rounded-circle" @click="endConversation" title="Mark as resolved">
                          <FeatherIcon type="x-square" />
                        </a>
                      </el-tooltip>
                      <el-tooltip class="item" effect="dark" content="Chat Information" placement="bottom">
                        <a class="btn btn-icon rounded-circle" @click="handlerShowDetailConvo(conversation.id)" title="Chat Info">
                          <FeatherIcon type="info" />
                        </a>
                      </el-tooltip>
                    </div>
                  </div>
                </div>
                <div class="chat-body">
                  <ul class="messages">
                    <li class="message-item system" v-if="!max_page.messages">
                      <div class="content">
                        <div class="message">
                          <div v-loading="loading.moreMessages" class="bubble" style="background: #fff !important; color: #000 !important;">
                            <a @click="loadMoreMessages" href="#">Load more</a>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li
                      class="message-item"
                      v-for="(message, i) in formatted_messages"
                      :key="i"
                      :class="message.formattedType">
                      <div v-if="message.type === 'in'" class="identicons" v-html="identicons(conversation.id)"/>
                      <div class="content" :id="'message-' + message.id" :style="isFromAgent(message) ? 'margin-right: 15px' : ''">
                        <div class="message" :class="{ 'has-quote': message.context && message.context.body }">
                          <ul class="message-action" :style="message.formattedType=='me'?'flex-direction: row-reverse;':''">
                            <li>
                              <div class="bubble">
                                <!-- For Quoted Message -->
                                <div v-if="message.context && message.context.body" class="quoted-message cursor-pointer" @click="goToQuotedMessage(message.context.id, message.context)">
                                  <b>{{ message.context.type === 'out' ? $t('general.you') : message.context.contact.name }}</b><br/>
                                  <message-item @handlePreview="handlePreview" :is_context="true" :message="message.context" @handleMediaDetail="handleMediaDetail"/>
                                </div>
                                <message-item @handlePreview="handlePreview" :message="message" @handleMediaDetail="handleMediaDetail"/>
                              </div>
                            </li>
                            <li :style="message.formattedType=='me'?'margin-right:-15px;':'margin-left:-15px;'">
                              <div v-if="!disableReply && message.channel=='whatsapp'">
                                <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                                  <template #button-content style="padding-left:0">
                                    <i class="mdi mdi-dots-vertical text-black"></i>
                                  </template>
                                  <b-dropdown-item @click="replyChat(message)">Reply</b-dropdown-item>
                                </b-dropdown>
                              </div>
                            </li>
                          </ul>
                          <span>
                            <i :class="[message.channel_icon, message.channel_color]"/>
                            {{ message.formattedTime }}
                            <i :class="{
                              'status-read': message.status === 'read',
                              'mdi-check-all': ['sent', 'delivered', 'read'].includes(message.status),
                              'mdi-clock': message.status == 'pending',
                              'mdi-close-circle-outline': message.status === 'failed',
                              'text-danger': message.status === 'failed'
                              }" v-if="message.type == 'out'" class="mdi"/>
                          </span>
                          <span v-if="message.type === 'out'">{{ isFromAgent(message) ? message.user.name : '' }}</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="chat-footer">
                  <ul class="nav nav-tabs mb-4" role="tablist">
                    <li class="nav-item">
                      <a @click="reply_type = 'reply'" class="nav-link active" id="reply-tab" data-toggle="tab" href="#reply" role="tab" aria-controls="reply" aria-selected="true">
                        <div class="d-flex flex-row flex-lg-column flex-xl-row align-items-center">
                          <p class="d-none d-sm-block">Reply</p>
                        </div>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a @click="reply_type = 'private'" class="nav-link" id="private-tab" data-toggle="tab" href="#private" role="tab" aria-controls="private" aria-selected="false">
                        <div class="d-flex flex-row flex-lg-column flex-xl-row align-items-center">
                          <p class="d-none d-sm-block">Private Chat</p>
                        </div>
                      </a>
                    </li>
                  </ul>
                  <div class="tab-content">
                    <div class="messages text-center" v-if="this.replyMessageContext" style="margin-bottom: -10px;" id="text-reply-message">
                      <div class="quoted-reply-message cursor-pointer">
                        <a style="margin-top: 3px; margin-right:10px; margin-left: -10px; font-size: 10px; color: #727272" class="float-left " @click="closeReplyChat" title="Close">
                          <font-awesome icon="times"/>
                        </a>
                        <b>{{ this.replyMessageContext.type === 'out' ? $t('general.you') : this.replyMessageContext.contact.name }}</b><br/>
                        <message-item @handlePreview="handlePreview" :is_context="true" :message="this.replyMessageContext"/>
                      </div>
                    </div>
                    <div class="tab-pane d-flex fade active show pr">
                      <div class="d-none d-md-block">
                        <el-tooltip content="Send attachment" placement="top">
                          <el-upload
                            :show-file-list="false"
                            action=''
                            :multiple="false"
                            :file-list="fileList"
                            :accept="acceptedExtentions"
                            :http-request="handleSendFile"
                            :disabled="conversation.status === 'resolved' || reply_type === 'private' || !can_reply_message">
                            <el-button :disabled="conversation.status === 'resolved' || reply_type === 'private' || !can_reply_message || loading.send_reply" class="mr-1" circle icon="el-icon-paperclip"/>
                          </el-upload>
                        </el-tooltip>
                      </div>
                      <div class="d-none d-md-block">
                        <el-tooltip content="Quick Reply" placement="top">
                          <el-button @click="() => { showModalQr = true; loadQrList(); }" border :disabled="conversation.status === 'resolved' || reply_type === 'private' || !can_reply_message || loading.send_reply" class="mr-2" circle icon="el-icon-chat-line-square">
                          </el-button>
                        </el-tooltip>
                      </div>
                      <div class="d-none d-md-block">
                        <el-tooltip content="Attach Product" placement="top">
                          <el-button @click="() => { showModalProduct = true; loadProductList(); }" border :disabled="conversation.status === 'resolved' || reply_type === 'private' || !can_reply_message || loading.send_reply" class="mr-2" circle icon="el-icon-goods">
                          </el-button>
                        </el-tooltip>
                      </div>
                      <div class="d-none d-md-block">
                        <el-tooltip content="Create Transaction" placement="top">
                          <el-button @click="() => { showModalCreateTrx = true; }" border :disabled="conversation.status === 'resolved' || reply_type === 'private' || !can_reply_message || loading.send_reply" class="mr-2" circle icon="el-icon-sell">
                          </el-button>
                        </el-tooltip>
                      </div>
                      <form class="search-form flex-grow mr-2" @submit.prevent="sendReply" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                        <div class="input-group ta-frame">
                          <b-button v-if="isDragging && replyMessage.length > 0" variant="outline-secondary" size="md" type="button" class="w-100" pill readonly="">Release to drop files here.</b-button>
                          <textarea @focus="() => last_index_emoji = null" v-else @keydown="handleInputKey" ref="message_input" :disabled="disableReply" type="text" class="form-control autoExpand" data-min-rows='1' :id="disableReply && (conversation.status === 'open' || conversation.status === 'handling') && reply_type !== 'private' ? 'privateForm' : 'chatForm'" :placeholder="isDragging ? 'Release to drop files here.' : 'Type a message. Use CTRL + Enter or ⌘ + Enter to send message'" v-model="replyMessage"></textarea>
                          <div class="input-group-append border-0" style="z-index: 999;">
                            <div class="input-group-text border-0"  style="background: none;">
                              <el-popover
                                placement="top"
                                width="380"
                                trigger="hover"
                                v-if="!disableReply"
                              >
                                <picker @select="addEmoji" size="16" :showPreview="false" />
                                <span slot="reference">
                                  <FeatherIcon type="smile" style="width: 16px;" />
                                </span>
                              </el-popover>
                              <FeatherIcon v-else type="smile" style="width: 16px;" />
                            </div>
                          </div>
                        </div>
                      </form>
                      <div>
                        <el-button :loading="loading.send_reply" :disabled="conversation.status === 'resolved' || loading.send_reply || !can_reply_message" type="primary" class="btn btn-primary btn-icon" @click="sendReply" circle icon="el-icon-s-promotion">
                        </el-button>
                      </div>
                      <b-button v-if="disableReply && (conversation.status === 'open' || conversation.status === 'handling') && reply_type !== 'private'" variant="outline-primary" size="md" type="button" class="w-auto ml-2" pill @click="() => { showModalReachTicket = true; }">Reach Ticket</b-button>
                    </div>
                  </div>
                </div>
                <div class="chat-footer d-flex mt-2" v-if="selected_filename !== ''">
                  <el-tag @close="clearSelectedFile" :closable="!loading.send_reply">
                    {{ selected_filename }}
                  </el-tag>
                </div>
              </div>
              <div :class="showDetailConversation ? 'col-lg-3' : 'd-none'" class="chat-aside border-lg-left fade-in" style="overflow-y: auto; max-height: 550px; height: auto;">
                <div class="chat-header border-bottom pb-2">
                  <div class="d-flex justify-content-between">
                    <div class="d-flex align-items-center">
                      <div class="identicons" v-html="identicons(conversation.id)"/>
                    </div>
                    <div class="d-flex align-items-center">
                      <a class="btn btn-icon rounded-circle" @click="showDetailConversation = !showDetailConversation" title="Chat Info">
                        <font-awesome icon="chevron-right"/>
                      </a>
                    </div>
                  </div>
                  <div class="mt-2">
                    <p><font-awesome icon="id-card" size="xs"/> <b>{{ conversation.contactName }}</b></p>
                    <p v-for="(item, index) in conversation.contacts" :key="index">
                      <span :class="item.color"><i :class="item.icon"></i></span>&nbsp;
                      <span v-if="['whatsapp', 'phone_call'].includes(item.channel)">{{ item.phone_number }}</span>
                      <span v-if="['instagram', 'fb_messenger'].includes(item.channel)">{{ item.original_name || '' }}</span>
                      <span v-if="item.channel == 'webchat'">(Web Chat)</span>
                    </p>
                    <p class="mt-2">
                      <a @click="shoModalEditContact" title="Edit Contact" class="btn btn-outline-primary btn-sm mr-2"><font-awesome icon="pen" size="xs"/></a>
                      <a v-if="!isMerged(conversation)" @click="shoModalMergeContact" title="Merge Contact" class="btn btn-outline-primary btn-sm mr-2"><font-awesome icon="code-branch" style="transform: rotate(-90dev)" size="xs"/></a>
                      <a v-if="!conversation.isContactBlocked" @click="handlerBtnBlockUnblock('block', conversation.contactId)" title="Block Contact" class="btn btn-outline-primary btn-sm"><font-awesome icon="ban" size="xs"/></a>
                      <a v-else @click="handlerBtnBlockUnblock('unblock', conversation.contactId)" title="Unblock Contact" class="btn btn-outline-primary btn-sm"><font-awesome icon="door-open" size="xs"/></a>
                    </p>
                    <convo-edit-contact
                      :show="modalEditContact"
                      :conversation="conversation"
                      :selected-contact="formEditContact"
                      @onHide="modalEditContact = false"
                    />
                    <convo-merge-contact
                      :show="modalMergeContact"
                      :selected-contact="selectedContactToMerge"
                      @onHide="modalMergeContact = false" />
                  </div>
                </div>
                <div class="d-flex justify-content-between border-bottom mt-3 pb-2 cursor-pointer" @click="collapsePlatformClick">
                  <h6>{{ $t('conversations.platform_used') }}</h6>
                  <a class="cursor-pointer"><font-awesome :icon="!isCollapsePlatform ? 'chevron-down' : 'chevron-up'"/></a>
                </div>
                <b-collapse v-model="isCollapsePlatform" class="mt-3 mb-2">
                  <span v-for="(item, index) in conversation.platform_used" :key="index" class="convo-platform mr-2" :class="item.value"><i :class="item.icon"></i></span>
                </b-collapse>
                <div class="d-flex justify-content-between border-bottom mt-3 pb-2 cursor-pointer" @click="collapseDetailClick">
                  <h6>{{ $t('conversations.action') }}</h6>
                  <a class="cursor-pointer"><font-awesome :icon="!isCollapseDetail ? 'chevron-down' : 'chevron-up'"/></a>
                </div>
                <b-collapse v-model="isCollapseDetail">
                  <div class="d-flex justify-content-between mt-3">
                    <form class="forms-sample">
                      <div class="form-group" :loading="loading.agent">
                        <label for="input-email"><FeatherIcon size="12" type="headphones" /> Assigned Agent</label>
                        <el-select :loading="loading.agent" :loading-text="$t('general.loading')" @change="reassignAgent" :disabled="['unhandled', 'resolved'].includes(conversation.status)" class="mr-3" size="small" v-model="assigned_to"
                        filterable
                        :filter-method="handlerSearchUser">
                          <el-option v-for="(agent) in agentList" :key="agent.id" :value="agent.id"
                            :label="agent.user.name"
                            :disabled="agent.status === 'deleted'"
                          />
                        </el-select>
                      </div>
                      <div class="form-group" :loading="loading.group">
                        <label for="input-email"><FeatherIcon size="12" type="users" /> Assigned Group</label>
                        <el-select :loading="loading.group" :loading-text="$t('general.loading')" filterable @change="reassignAgentGroup" :disabled="['unhandled', 'resolved'].includes(conversation.status)" class="mr-3" size="small" v-model="assigned_group_to"
                        :filter-method="handlerSearchAgentGroup"
                        >
                          <el-option v-for="(group) in groupList" :key="group.id" :value="group.id"
                            :label="group.name"
                            :disabled="group.status === 'deleted'"
                          />
                        </el-select>
                      </div>
                      <div class="form-group" :loading="loading.label">
                        <label for="input-email"><FeatherIcon size="12" type="tag" /> Label</label><br/>
                        <el-tag
                          class="mr-2 mb-2"
                          v-for="(label) in conversation.labels"
                          :key="label.id"
                          :closable="!loading.update_label || !['unhandled', 'resolved'].includes(conversation.status)"
                          @close="tagLabelClosed(label.id)"
                          size="small"
                          :style="{ 'background-color': label.color, 'color': 'white' }"
                          effect="dark">
                          {{ label.title }}
                        </el-tag>
                        <el-button :disabled="['unhandled', 'resolved'].includes(conversation.status)" v-if="!showInputLabel" class="button-new-tag" size="mini" @click="showInputNewLabel">+ Add Label</el-button>
                        <el-select :loading="loading.label" :loading-text="$t('general.loading')" @change="selectedLabelChanged" automatic-dropdown collapse-tags ref="select_new_tag" v-if="showInputLabel" filterable multiple :disabled="['unhandled', 'resolved'].includes(conversation.status)" size="mini" v-model="selected_label">
                          <el-option v-for="(label) in labelList" :key="label.id" :value="label.id"
                            :label="label.title"
                            :disabled="label.status === 'deleted' || loading.update_label"
                          />
                        </el-select>
                      </div>
                      <!-- <div class="form-group">
                        <label for="input-password">Password</label>
                        <input type="password" class="form-control" id="input-password" placeholder="Password" v-model="password" name="password" required>
                      </div> -->
                    </form>
                  </div>
                </b-collapse>
                <div class="d-flex justify-content-between border-bottom mt-3 pb-2 cursor-pointer" @click="collapseTransactionClick">
                  <h6>{{ $t('conversations.trx') }}</h6>
                  <a class="cursor-pointer"><font-awesome :icon="!isCollapseTransaction ? 'chevron-down' : 'chevron-up'"/></a>
                </div>
                <b-collapse v-model="isCollapseTransaction">
                  <transaction-list
                    :conversationId="conversation.id"
                    :expanded="isCollapseTransaction"/>
                  <!-- <div v-if="!isMoreResultHistory" align="center" class="mt-2 mb-2">
                    <p>No transactions</p>
                  </div> -->
                </b-collapse>
                <div class="d-flex justify-content-between border-bottom mt-3 pb-2 cursor-pointer" @click="collapseHistoryClick">
                  <h6>{{ $t('conversations.history') }}</h6>
                  <a class="cursor-pointer"><font-awesome :icon="!isCollapseHistory ? 'chevron-down' : 'chevron-up'"/></a>
                </div>
                <b-collapse v-model="isCollapseHistory">
                  <div v-loading="loading.history" style="overflow-y: auto;">
                    <!-- <perfect-scrollbar> -->
                      <ul class="list-unstyled chat-list mt-2">
                        <li
                          class="chat-item pr-2 mt-2"
                          @click="showDetailChatHistory({ conversation_id: conversation.id })"
                          v-for="(conversation, i) in conversationHistoryList"
                          :key="i">
                          <a href="javascript:;" class="d-flex align-items-center">
                            <div class="identicons" v-html="identicons(conversation.id)" />
                            <div class="flex-grow border-bottom">
                              <div class="d-flex justify-content-between align-items-center">
                                <p class="text-body font-weight-bold">#{{ conversation.code }}</p>
                              </div>
                              <div class="mb-2">
                                <p class="text-muted tx-10 preview-chat">
                                  {{ conversation.formattedTime }}
                                </p>
                              </div>
                            </div>
                          </a>
                        </li>
                      </ul>
                      <div v-loading="loading.load_more" v-if="isMoreResultHistory" align="center">
                        <a @click="infiniteHandler">Load more</a>
                      </div>
                      <div v-if="!isMoreResultHistory" align="center">
                        <p>No more previous conversations</p>
                      </div>
                    <!-- </perfect-scrollbar> -->
                  </div>
                </b-collapse>
                <div class="d-flex justify-content-between border-bottom mt-3 pb-2 cursor-pointer" @click="collapseNotesClick">
                  <h6>{{ $t('conversations.notes.title') }}</h6>
                  <a class="cursor-pointer"><font-awesome :icon="!isCollapseNotes ? 'chevron-down' : 'chevron-up'"/></a>
                </div>
                <b-collapse v-model="isCollapseNotes">
                  <div class="row">
                    <div class="col-lg-12">
                      <el-input
                        show-word-limit
                        :maxlength="1024"
                        type="textarea"
                        rows="4"
                        v-model="conversation.notes"
                        placeholder="Notes about this ticket"
                      />
                      <div class="float-right mt-2 mb-2">
                        <el-button :loading="loading.update_notes" @click="handlerBtnSaveNotes" type="button" class="btn btn-outline-primary btn-sm">{{ $t('conversations.notes.save') }}</el-button>
                      </div>
                    </div>
                  </div>
                </b-collapse>
                <div class="d-flex justify-content-between border-bottom mt-3 pb-2 cursor-pointer" @click="collapseMediaClick">
                  <h6>{{ $t('conversations.media') }}</h6>
                  <a class="cursor-pointer"><font-awesome :icon="!isCollapseMedia ? 'chevron-down' : 'chevron-up'"/></a>
                </div>
                <b-collapse v-model="isCollapseMedia">
                  <div v-loading="loading.media" style="overflow-y: auto;">
                    <!-- <perfect-scrollbar> -->
                      <!-- <ul class="list-unstyled chat-list mt-2"> -->
                      <div class="row">
                        <div
                          class="pr-2 mt-2 col-lg-6"
                          v-for="(message, i) in conversationMediaList"
                          :key="i">
                          <a href="javascript:;" @click="goToQuotedMessage(message.id, message)" class="d-flex align-items-center">
                            <!-- <div class="identicons" v-html="identicons(conversation.id)" /> -->
                            <div class="flex-grow border-bottom">
                              <div class="d-flex justify-content-between align-items-center">
                                <span v-if="message.body_type == 'document'">
                                  <FeatherIcon type="file-text" size="40px" class="mt-2 align-items-center" />
                                  <p>{{ parseHtmlAnchor(message.caption) || $t('general.document') }}</p>
                                </span>
                                <img v-if="message.body_type == 'image'"
                                  :src="message.body"
                                  style="object-fit: contain; width: 100%; height: 100%;"
                                  @load="onImageLoad"
                                />
                                <video v-if="message.body_type == 'video'" :src="message.body" style="object-fit: contain; width: 100%; height: 100%;"></video>
                                <!-- <p class="text-body font-weight-bold">#{{ message.id }}</p> -->
                              </div>
                              <div class="mb-2">
                                <p class="text-muted tx-10 preview-chat">
                                  {{ message.created_at_str }}
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                      <!-- </ul> -->
                      <div v-loading="loading.load_more" v-if="isMoreResultMedia" align="center">
                        <a @click="infiniteHandlerMedia">Load more</a>
                      </div>
                      <div v-if="!isMoreResultMedia" align="center">
                        <p>No more previous conversations</p>
                      </div>
                    <!-- </perfect-scrollbar> -->
                  </div>
                </b-collapse>
                <div class="d-flex justify-content-between border-bottom mt-3 pb-2 cursor-pointer" @click="collapseLeadsClick">
                  <h6>{{ $t('conversations.leads') }}</h6>
                  <a class="cursor-pointer"><font-awesome :icon="!isCollapseLeads ? 'chevron-down' : 'chevron-up'"/></a>
                </div>
                <b-collapse v-model="isCollapseLeads">
                  <div v-loading="loading.leads" style="overflow-y: auto;">
                    <!-- <perfect-scrollbar> -->
                      <ul class="list-unstyled chat-list mt-2">
                        <li
                          class="chat-item pr-2 mt-2"
                          @click="showDetailLeadHistory(lead.id)"
                          v-for="(lead, i) in conversationLeadsList"
                          :key="i">
                          <a href="javascript:;" class="d-flex align-items-center">
                            <div class="identicons" v-html="identicons(lead.user?.name)" />
                            <div class="flex-grow border-bottom">
                              <div class="d-flex justify-content-between align-items-center">
                                <p class="text-body font-weight-bold">{{ lead.name }}</p>
                              </div>
                              <div class="mb-2">
                                <p class="text-muted tx-10 preview-chat">
                                  {{ lead.formattedTime }}
                                </p>
                              </div>
                            </div>
                          </a>
                        </li>
                      </ul>
                      <div v-loading="loading.load_more" v-if="isMoreResultLeads" align="center">
                        <a @click="conversationLeads((leadsPage+1), conversation.contactId)">Load more</a>
                      </div>
                      <div v-if="!isMoreResultLeads" align="center">
                        <p>No more leads history</p>
                      </div>
                    <!-- </perfect-scrollbar> -->
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <convo-quick-reply
      :show="showModalQr"
      @appendQr="appendQr"
      @onHide="showModalQr = false"
    />
    <convo-product-list
      :show="showModalProduct"
      @onClose="showModalProduct = false"
      @appendProduct="appendProduct"
    />
    <convo-create-transaction
      :show="showModalCreateTrx"
      @onClose="showModalCreateTrx = false"
      @afterSubmit="afterSubmitTrx"
    />
    <convo-reach-ticket
      :show="showModalReachTicket"
      :conversation_id="conversation.id"
      @onClose="showModalReachTicket = false"
      @resetChat="showDetailChat({ conversation_id: conversation.id }); resetListConversation(false);"
    />
    <convo-media-detail
      :show="showModalMediaDetail"
      @onClose="showModalMediaDetail = false"
      :url="url_media_detail"
      :type="type_media_detail"
    />
    <preview-quoted-message
      :show="showModalPreviewQuoted"
      :message="selectedQuotedMessage"
      @onHide="showModalPreviewQuoted = false"
    />
    <!-- <preview-phone-call
      :show="showModalPhoneCall"
      :conversation="conversation"
      @onHide="showModalPhoneCall = false"
    />
    <preview-incoming-call
      :show="showModalIncomingCall"
      :conversation="conversation"
      :call_session="call_session"
      @onHide="showModalIncomingCall = false"
    /> -->
    <!-- preview image -->
    <b-modal v-model="showModalZoomImage" title="Image" hide-footer size="lg">
      <v-zoomer
        style="width: 100%; height: 650px;"
        ref="zoomer"
        :aspect-ratio="imageAspectRatio"
        :max-scale="10"
        :zooming-elastic="false"
        :zoomed.sync="zoomed"
      >
        <img
          :src="zoomImageUrl"
          style="object-fit: contain; width: 100%; height: 100%;"
          @load="onImageLoad"
        />
      </v-zoomer>
      <hr>
      <div class="d-flex flex-row mt-2 float-right">
        <button type="button" class="btn w-auto mr-2 btn-outline-primary btn-md rounded-pill" @click="$refs.zoomer.zoomIn()">+</button>
        <button type="button" class="btn w-auto mr-2 btn-outline-primary btn-md rounded-pill" @click="$refs.zoomer.zoomOut()">-</button>
        <b-button type="button" variant="primary" class="" @click="downloadZoomImage()">Download</b-button>
      </div>
    </b-modal>
    <create-leads
      :show="showModalCreateLeads"
      :selected_contact="conversation?.selected_contact"
      @onClose="showModalCreateLeads = false"
      @onSubmit="submitLeads()"
    ></create-leads>
  </div>
</template>

<script>
import moment from 'moment';
import PerfectScrollbar from 'perfect-scrollbar';
import openSocket from 'socket.io-client';
// import JsSIP from 'jssip';
import Push from 'push.js';
import _ from 'lodash';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import mime from 'mime';
import avatar from '@/library/avatar';
import groupsAPI from '@/api/agentGroups';
import agentsAPI from '@/api/agents';
import labeslApi from '@/api/labels';
import qrApi from '@/api/quickReplies';
import contactApi from '@/api/contacts';
import productApi from '@/api/commerceProducts';
import leadsAPI from '@/api/leads';
// import agentGroupsAPI from '../../../api/agentGroups';
import config from '@/config';
import popupErrorMessages from '@/library/popup-error-messages';
import MessageItem from '@/ui/components/MessageItem.vue';
import noInbox from '@/assets/images/undraw/no-inbox.svg';
import emptyImage from '@/assets/images/undraw/empty.svg';
import { toSvg } from 'jdenticon';
import VueZoomer from 'vue-zoomer/src';
import { Picker } from 'emoji-mart-vue-2';
import conversationsAPI from '../../api/conversations';
import CreateLeads from '../components/conversations/CreateLeads.vue';
import TransactionList from '../components/conversations/TransactionList.vue';
import ConvoProductList from '../components/conversations/ProductList.vue';
import ConvoCreateTransaction from '../components/conversations/CreateTransaction.vue';
import ConvoQuickReply from '../components/conversations/QuickReply.vue';
import ConvoEditContact from '../components/conversations/EditContact.vue';
import ConvoMergeContact from '../components/conversations/MergeContact.vue';
import ConvoReachTicket from '../components/conversations/ReachTicket.vue';
import ConvoMediaDetail from '../components/conversations/MediaDetail.vue';
import PreviewQuotedMessage from '../components/conversations/PreviewQuotedMessage.vue';
import { ui_call_socket } from '../components/Navbar.vue';
// import PreviewPhoneCall from '../components/conversations/PreviewPhoneCall.vue';
// import PreviewIncomingCall from '../components/conversations/PreviewIncomingCall.vue';
import NotifSound from '../../assets/sound/Block.mp3';

const socket = openSocket(config.api.chatUrl, {
  transports: ['websocket'],
  upgrade: false,
  reconnection: true,
  reconnectionAttempts: 1000,
  reconnectionDelay: 1000,
});

export default {
  components: {
    MessageItem,
    TransactionList,
    ConvoProductList,
    ConvoCreateTransaction,
    ConvoQuickReply,
    ConvoEditContact,
    ConvoMergeContact,
    ConvoReachTicket,
    ConvoMediaDetail,
    PreviewQuotedMessage,
    CreateLeads,
    // VueImageZoomer,
    // ImageZoom,
    // VueZoomer,
    VZoomer: VueZoomer.Zoomer,
    Picker,
  },
  beforeCreate() {
    this.moment = moment;
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  name: 'Conversations',
  metaInfo: {
    title: 'Conversations',
  },
  data() {
    return {
      call_session: {},
      call: {
        duration: 0,
        startTime: '',
        talktime: 0,
        incoming_number: '',
        incoming_name: '',
      },
      phone_call: {
        status: '',
        mic: 'mdi mdi-microphone mdi-24px',
        volume: 'mdi mdi-volume-low mdi-24px',
        name: '',
        phone_number: '',
      },
      showModalIncomingCall: false,
      showModalPreviewQuoted: false,
      selectedQuotedMessage: {},
      truncate: _.truncate,
      upperFirst: _.upperFirst,
      NotifSound,
      noInbox,
      emptyImage,
      leadsPage: 1,
      isCollapseHistory: true,
      isCollapseMedia: true,
      isCollapseDetail: true,
      isCollapseTransaction: false,
      isCollapsePlatform: true,
      isCollapseNotes: false,
      isCollapseLeads: false,
      isAllowedCall: false,
      showModalQr: false,
      showModalPreview: false,
      showModalProduct: false,
      showModalCreateTrx: false,
      showModalReachTicket: false,
      showModalMediaDetail: false,
      showModalPhoneCall: false,
      showModalCreateLeads: false,
      isVisibleLabelFilter: false,
      url_media_detail: '',
      type_media_detail: '',
      showModalZoomImage: false,
      zoomImageUrl: '',
      zoomed: false,
      imageAspectRatio: 1,
      selected_preview: {},
      qrList: [],
      productList: [],
      loading: {
        chat_list: true,
        chat_detail: false,
        send_reply: false,
        load_more: false,
        agent: false,
        history: true,
        media: false,
        update_label: false,
        qrlist: false,
        moreMessages: false,
        update_contact: false,
        label: false,
        group: false,
        productList: false,
        update_notes: false,
        phone_call: false,
        leads: false,
      },
      searchForm: {
        assigned_to: 'all',
        search_by: 'code',
        sort_by: 'updated_at:desc',
        label_id: '',
        labels_id: [],
      },
      assigned_to_list: [
        { text: 'Mine', value: '' },
        { text: 'My Group', value: 'my_group' },
        { text: 'All', value: 'all' },
        { text: 'Bot', value: 'bot' },
      ],
      search_by_list: [
        { text: 'Phone', value: 'phone' },
        { text: 'Code', value: 'code' },
        { text: 'Labels', value: 'label' },
      ],
      sort_by_list: [
        { text: 'Newest Chat', value: ['updated_at', 'desc'].join(':') },
        { text: 'Oldest Chat', value: ['updated_at', 'asc'].join(':') },
      ],
      loaderStack: 0,
      loader: null,
      perfectScroll: [],
      showDetail: false,
      conversations: [],
      messages: [],
      conversation: {},
      rows_pin: {},
      loadedConversation: false,
      replyMessage: '',
      userProfile: this.$store.state.auth.userProfile,
      selected_conversation_id: '',
      selected_conversation_status: 'open',
      pagination: {
        page: 1,
        messages: 1,
      },
      max_page: {
        messages: false,
      },
      busy: true,
      isMoreResult: true,
      showDetailConversation: false,
      conversationLeadsList: [],
      conversationHistoryList: [],
      conversationMediaList: [],
      notes: '',
      agentList: [],
      groupList: [],
      assigned_to: '',
      assigned_group_to: '',
      isMoreResultHistory: false,
      isMoreResultMedia: false,
      isMoreResultLeads: false,
      fileList: [],
      selectedFile: {},
      selected_filename: '',
      disable_input: false,
      labelList: [],
      selected_label: [],
      showInputLabel: false,
      reply_type: 'reply',
      can_reply_message: true,
      page: {
        qr: 1,
        product: 1,
      },
      totalRow: {
        qr: 0,
        product: 0,
      },
      raw_msg: {
        agent_id: null,
        body: '',
        body_type: 'text',
        caption: null,
        contact: {},
        contact_id: '',
        context: {},
        conversation_id: '',
        created_at: '',
        delivered_at: '',
        failed_at: '',
        failed_error_code: '',
        failed_error_title: '',
        filename: null,
        formattedTime: '',
        formattedType: 'me',
        id: 0,
        is_read: true,
        msg_id: '',
        quoted_msg_id: null,
        read_at: null,
        reassign_ticket_id: null,
        sent_at: '',
        status: '',
        type: 'out',
        updated_at: '',
        user: {
          name: this.$store.state.auth.userProfile.name,
        },
        user_id: this.$store.state.auth.userProfile.id,
        is_loading: false,
        reconnect: true,
      },
      status_count: {
        open: 0,
        handling: 0,
        resolved: 0,
      },
      formEditContact: {},
      modalEditContact: false,
      modalMergeContact: false,
      selectedContactToMerge: {},
      channelList: [
        { name: 'WhatsApp', icon: 'mdi mdi-whatsapp', value: 'whatsapp' },
        { name: 'Telegram', icon: 'mdi mdi-telegram', value: 'telegram' },
        { name: 'Email', icon: 'mdi mdi-email', value: 'email' },
        { name: 'Instagram', icon: 'mdi mdi-instagram', value: 'instagram' },
        { name: 'Webchat', icon: 'mdi mdi-forum-outline', value: 'webchat' },
        { name: 'Facebook Messenger', icon: 'mdi mdi-facebook-messenger', value: 'fb_messenger' },
        { name: 'Phone Call', icon: 'mdi mdi-phone', value: 'phone_call' },
      ],
      blocked_reason: '',
      timeout_search_agent_group: null,
      timeout_search_user: null,
      isDragging: false,
      last_index_emoji: null,
      replyMessageContext: null,
      steps_list: [],
    };
  },
  computed: {
    buttonCallStyle() {
      return ui_call_socket.button_call_color;
    },
    buttonCallActive() {
      return ui_call_socket.button_call_active;
    },
    acceptedExtentions() {
      if (this.conversation.active_channel === 'instagram') {
        return '.png, .jpeg, .jpg, .mp4';
      }
      return '.png, .pdf, .jpeg, .jpg, .mp4, .xlsx, .xls, .doc, .docx, .txt';
    },
    formatted_messages() {
      return _.uniqBy(this.messages.filter((v) => v.id), 'id');
    },
    currentPageQr: {
      get() {
        return this.page.qr;
      },
      set(val) {
        this.page.qr = val;
        this.loadQrList();
      },
    },
    currentPageProduct: {
      get() {
        return this.page.product;
      },
      set(val) {
        this.page.product = val;
        this.loadProductList();
      },
    },
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    userAvatar() {
      return avatar(this.userProfile.name, this.userProfile.email);
    },
    urlQuery() {
      const { query } = this.$route;
      return query;
    },
    urlParams() {
      const { params } = this.$route;
      return params;
    },
    disableReply() {
      return this.conversation.status === 'resolved' || this.loading.send_reply || this.disable_input || !this.can_reply_message || this.conversation.last_msg_in_at == null;
    },
    isAgent() {
      if (!_.isEmpty(this.userProfile.agents[0])) {
        const agent_data = this.userProfile.agents[0];
        if (agent_data.assigned_call_feature === true && agent_data.is_available === true) {
          return true;
        }
        return false;
      }
      return false;
    },
    isEnableCallFeature() {
      if (this.activeWorkspace._id && this.activeWorkspace.products && this.userProfile.agents[0]?.workspace_id === this.activeWorkspace._id) {
        return this.activeWorkspace.products[0].pricing.whatsapp.enable_call_feature;
      }
      return false;
    },
    isEnableVoIPFeature() {
      if (this.activeWorkspace._id && this.activeWorkspace.products && this.userProfile.agents[0]?.workspace_id === this.activeWorkspace._id) {
        return this.$store.state.auth.sip?.access?.extension ? this.activeWorkspace.products[0].pricing.phone_call.enable_feature : false;
      }
      return false;
    },
  },
  beforeDestroy() {
    this.reconnect = false;
    socket.disconnect();
  },
  async mounted() {
    this.getLabelList();
    const is_agent = !_.isEmpty(this.userProfile.agents[0]);
    const { is_pic } = this.$store.state.auth.userProfile;
    if (!is_pic) {
      if (is_agent) {
        this.searchForm.assigned_to = '';
      }
    }
    Push.Permission.request();
    if (document.querySelectorAll('.chat-aside .tab-content').length) {
      this.perfectScroll.push(new PerfectScrollbar('.chat-aside .tab-content'));
    }
    if (document.querySelectorAll('.chat-content .chat-body').length) {
      this.perfectScroll.push(new PerfectScrollbar('.chat-content .chat-body'));
      this.scrollChatToBottom();
    }
    if (document.querySelectorAll('.chat-history').length) {
      this.perfectScroll.push(new PerfectScrollbar('.chat-history'));
      this.scrollToTopHistory();
    }

    const div = document.querySelector('.ta-frame');
    const ta = document.querySelector('textarea');

    ta.addEventListener('keydown', () => {
      setTimeout(() => {
        ta.style.cssText = 'height: 40px';
        const height = Math.min(20 * 3, ta.scrollHeight);
        div.style.cssText = `height: ${height}px`;
        ta.style.cssText = `height: ${height}px`;
      }, 0);
    });

    this.replyMessageContext = null;
    this.showLoader();
    await this.loadConversations();
    this.hideLoader();
    this.doConnectSocket();
    socket.on('listen_messages', (data) => {
      const { message } = data;
      const mappedMessage = this.constructMessage(message);
      if (message.conversation_id === this.selected_conversation_id) {
        if (message.status !== 'deleted') {
          this.messages.push(mappedMessage);
          this.messages = _.uniqBy(this.messages, 'id');
          if (message.interactive_type === 'call_permission_reply' && message.conversation_id === this.selected_conversation_id) {
            this.getCallRequestStatus(message.conversation_id);
          }
          this.scrollChatToBottom();
        } else {
          this.messages = this.messages.map((v) => {
            if (v.id === message.id) {
              v.body = 'This messages was deleted by user';
              v.status = 'deleted';
            }
            return v;
          });
        }
      }
      this.updateLastMessage(mappedMessage);
    });
    socket.on('listen_conversation', (data) => {
      const {
        type, message, conversation, ticket,
      } = data;
      if (type === 'message') {
        const audio = new Audio(NotifSound);
        // if (conversation.status !== 'unhandled') {
        //   audio.play();
        // }
        if (message.type === 'in' && conversation.status !== 'unhandled') audio.play();
        if (conversation.status === this.selected_conversation_status) {
          const mappedMessage = this.constructMessage(message);
          this.updateLastMessage(mappedMessage);
        }
        if (conversation.id !== this.selected_conversation_id) {
          let body_to_push = message.body;
          if (message.body_type === 'image') {
            body_to_push = `📷 ${(message.caption || this.$t('general.image'))}`;
          } else if (message.body_type === 'video') {
            body_to_push = `📹 ${(message.caption || this.$t('general.video'))}`;
          } else if (message.body_type === 'location') {
            body_to_push = `📍 ${(message.caption || this.$t('general.location'))}`;
          } else if (message.body_type === 'audio') {
            body_to_push = `🔈 ${(this.$t('general.audio'))}`;
          } else if (message.body_type === 'document') {
            body_to_push = `📄 ${(this.$t('general.document'))}`;
          }
          Push.create(this.$t('conversations.new_message.title').replace('{{ticket_code}}', conversation.code), {
            body: body_to_push,
            icon: '/logo-wappin.png',
            timeout: 4000,
            onClick: () => {
              window.focus();
              if (conversation.status !== this.selected_conversation_status) {
                this.$refs[`${conversation.status}_status_tab`].click();
              }
              this.showDetailChat({ conversation_id: conversation.id });
            },
          });
        }
      } else if (type === 'ticket') {
        if (this.selected_conversation_status === 'open') {
          this.loadConversations();
        }
        Push.create(this.$t('conversations.new_conversation.title'), {
          body: this.$t('conversations.new_conversation.description').replace('{{ticket_code}}', ticket.code),
          icon: '/logo-wappin.png',
          timeout: 4000,
          onClick: () => {
            window.focus();
            if (ticket.status !== this.selected_conversation_status) {
              this.$refs[`${ticket.status}_status_tab`].click();
            }
            this.showDetailChat({ conversation_id: ticket.id });
          },
        });
      }
    });
    socket.on('listen_all_conversation', (data) => {
      const {
        type, message, conversation, ticket,
      } = data;
      if (type === 'message') {
        // console.log('listen_all_conversation', data);
        const audio = new Audio(NotifSound);
        if (message.type === 'in' && conversation.status !== 'unhandled') audio.play();
        const mappedMessage = this.constructMessage(message);
        this.updateLastMessage(mappedMessage);
        if (message.interactive_type === 'call_permission_reply' && conversation.id === this.selected_conversation_id) {
          this.getCallRequestStatus(message.conversation_id);
        }
        if (conversation.id !== this.selected_conversation_id) {
          let body_to_push = message.body;
          if (message.body_type === 'image') {
            body_to_push = `📷 ${(message.caption || this.$t('general.image'))}`;
          } else if (message.body_type === 'video') {
            body_to_push = `📹 ${(message.caption || this.$t('general.video'))}`;
          } else if (message.body_type === 'location') {
            body_to_push = `📍 ${(message.caption || this.$t('general.location'))}`;
          } else if (message.body_type === 'audio') {
            body_to_push = `🔈 ${(this.$t('general.audio'))}`;
          } else if (message.body_type === 'document') {
            body_to_push = `📄 ${(this.$t('general.document'))}`;
          }
          if (conversation.code) {
            Push.create(this.$t('conversations.new_message.title').replace('{{ticket_code}}', conversation.code), {
              body: body_to_push,
              icon: '/logo-wappin.png',
              timeout: 4000,
              onClick: () => {
                window.focus();
                if (conversation.status !== this.selected_conversation_status) {
                  this.$refs[`${conversation.status}_status_tab`].click();
                }
                this.showDetailChat({ conversation_id: conversation.id });
              },
            });
          }
        } else {
          this.messages.push(mappedMessage);
          this.messages = _.uniqBy(this.messages, 'id');
          this.scrollChatToBottom();
        }
      } else if (type === 'ticket') {
        if (this.selected_conversation_status === 'open') {
          this.loadConversations();
        }
        Push.create(this.$t('conversations.new_conversation.title'), {
          body: this.$t('conversations.new_conversation.description').replace('{{ticket_code}}', ticket.code),
          icon: '/logo-wappin.png',
          timeout: 4000,
          onClick: () => {
            window.focus();
            if (ticket.status !== this.selected_conversation_status) {
              this.$refs[`${ticket.status}_status_tab`].click();
            }
            this.showDetailChat({ conversation_id: ticket.id });
          },
        });
      }
    });
    socket.on('connect', () => {
      console.log('connected to socket');
      if (this.$store.state.auth.userProfile.agents[0]) {
        const agent_id = this.$store.state.auth.userProfile.agents[0].id;
        socket.emit('conversation', agent_id);
      }
      if (this.selected_conversation_id) {
        socket.emit('messages', this.selected_conversation_id);
      }
      socket.emit('ws-conversation', `conversation:${this.activeWorkspace._id}`);
    });
    if (this.$route.query.code && this.$route.query.convo && this.$route.query.state) {
      this.searchForm.search = this.$route.query.code;
      await this.tabStatusConvoClicked(this.$route.query.state);
      this.selected_conversation_status = this.$route.query.state;
      await this.searchByChanged();
      this.pagination.messages = 1;
      await this.showDetailChat({ conversation_id: this.$route.query.convo });
    }
    this.leadsSetting();
  },
  methods: {
    submitLeads() {
      this.$router.push('/leads/board');
    },
    async leadsSetting() {
      const response = await leadsAPI.get_steps({ workspace_id: this.activeWorkspace._id });
      await popupErrorMessages(response);
      if (response.data.steps) {
        this.steps_list = response.data.steps;
      }
    },
    async getCallRequestStatus(conversation_id) {
      const response = await conversationsAPI.checkCallRequestStatus({
        workspace_id: this.activeWorkspace._id,
        conversation_id,
      });
      if (!response.error) {
        this.$set(this.conversation, 'call_request_status', response.data.status);
      }
    },
    async handleInitRequestCall() {
      this.showLoader();
      const response = await conversationsAPI.initCallRequest({
        workspace_id: this.activeWorkspace._id,
        conversation_id: this.conversation.id,
      });
      this.hideLoader();
      try {
        await popupErrorMessages(response);
        this.$message({
          type: 'success',
          message: this.$t('general.success'),
        });
        this.showDetailChat({ conversation_id: this.conversation.id });
      } catch (error) {
        console.log(error);
      }
    },
    async doCall() {
      this.$parent.$refs.navbar.createSdpOffer(async (err, session) => {
        this.showLoader();
        const response = await conversationsAPI.executeCall({
          workspace_id: this.activeWorkspace._id,
          conversation_id: this.conversation.id,
          session: {
            sdp: session.sdp,
            type: 'offer',
          },
        });
        if (!response.error) {
          this.$parent.$refs.navbar.waitCallbackSdp(response.data.call_id, this.conversation);
        }
        this.hideLoader();
        popupErrorMessages(response).catch(() => {});
      });
    },
    formattedChannelName(value) {
      const selected_channel = this.channelList.find((v) => v.value === value);
      if (!selected_channel) {
        return '-';
      }
      return selected_channel.name;
    },
    dragover(e) {
      e.preventDefault();
      if (!this.disableReply) {
        this.isDragging = true;
      }
    },
    dragleave() {
      if (!this.disableReply) {
        this.isDragging = false;
      }
    },
    drop(e) {
      e.preventDefault();
      if (!this.disableReply) {
        // console.log(e.dataTransfer.files);
        if (e.dataTransfer.files[0]) {
          this.handleSendFile({ file: e.dataTransfer.files[0] });
        }
        this.isDragging = false;
      }
    },
    async handlerShowDetailConvo(conversation_id) {
      this.showDetailConversation = !this.showDetailConversation;
      this.refreshConvoDetail(conversation_id);
    },
    async refreshConvoDetail(conversation_id) {
      const response = await conversationsAPI.getListDetail(this.activeWorkspace._id, conversation_id, this.pagination.messages, true);
      if (!response.error) {
        this.conversation = response.data.conversation;
        this.rows_pin = response.data.rows_pin;
        this.assigned_to = this.conversation.agent ? this.conversation.agent.id : '';
        this.conversations.contacts = response.data.conversation.contacts.map((v) => {
          const ch = this.channelList.find((c) => c.value === v.channel);
          v.icon = ch.icon;
          v.color = `text-${ch.value}`;
          return v;
        });
        // const selected_contact = this.conversation.contacts.find((v) => v.channel === this.conversation.active_channel);
        const parent_contact = this.conversation.contacts.find((v) => !_.isEmpty(v.child_ids) || v.status === 'active');
        this.conversation.selected_contact = parent_contact;
        this.conversation.contactName = parent_contact.name;
        this.conversation.phoneNumber = parent_contact.phone_number;
        if (parent_contact.channel === 'instagram') {
          this.conversation.phoneNumber = parent_contact.original_name || '';
        }
        if (parent_contact.channel === 'webchat') {
          this.conversation.phoneNumber = '';
        }
        this.conversation.isContactBlocked = parent_contact.is_blocked;
        this.conversation.contactId = parent_contact.id;
        this.conversation.platform_used = response.data.conversation.channels.map((v) => this.channelList.find((ch) => ch.value === v));
        this.assigned_to = this.conversation.agent ? this.conversation.agent.id : '';
        this.assigned_group_to = this.conversation.agent_group ? this.conversation.agent_group.id : '';
        this.selected_label = this.conversation.labels.map((v) => v.id) || [];
      }
    },
    zoomImage(url) {
      this.showModalZoomImage = true;
      this.zoomImageUrl = url;
    },
    onImageLoad(e) {
      const img = e.target;
      this.imageAspectRatio = img.naturalWidth / img.naturalHeight;
    },
    // downloadImg() {
    //   fetch(this.zoomImageUrl)
    //     .then((response) => response.blob())
    //     .then((blob) => {
    //       saveAs(blob, 'image_name.jpg');
    //     });
    //   // console.log('downloading', url);
    // },
    async downloadZoomImage() {
      const blob = await (await fetch(this.zoomImageUrl)).blob();
      const url = URL.createObjectURL(blob);
      // eslint-disable-next-line new-cap
      const file_name = `${Date.now()}.jpg`;
      Object.assign(document.createElement('a'), {
        href: url,
        download: file_name,
      }).click();
      URL.revokeObjectURL(url);
    },
    isMerged(conversation) {
      const mergedContact = conversation && conversation.contacts ? conversation.contacts.filter((v) => v.status === 'merged') : [];
      const hasParentOrChild = conversation && conversation.contacts ? conversation.contacts.filter((v) => !_.isEmpty(v.parent_id) || !_.isEmpty(v.child_ids)) : [];
      return !_.isEmpty(mergedContact) || !_.isEmpty(hasParentOrChild);
    },
    collapseLeadsClick() {
      this.conversationLeadsList = [];
      this.leadsPage = 1;
      const contacts = this.conversation.contacts.find((v) => v.channel === 'whatsapp');
      this.$nextTick(() => {
        this.isCollapseLeads = !this.isCollapseLeads;
        setTimeout(() => {
          if (this.isCollapseLeads) this.conversationLeads(this.leadsPage, contacts.id);
        }, 550);
      });
    },
    collapseHistoryClick() {
      this.conversationHistoryList = [];
      this.$nextTick(() => {
        this.isCollapseHistory = !this.isCollapseHistory;
        setTimeout(() => {
          if (this.isCollapseHistory) this.conversationHistory(this.conversation.contacts[0].id);
        }, 550);
      });
    },
    collapseMediaClick() {
      this.conversationMediaList = [];
      this.pagination_media = [];
      this.pagination_media.page = 1;
      this.$nextTick(() => {
        this.isCollapseMedia = !this.isCollapseMedia;
        setTimeout(() => {
          if (this.isCollapseMedia) this.conversationMedia(this.conversation.id);
        }, 550);
      });
    },
    collapseTransactionClick() {
      this.isCollapseTransaction = !this.isCollapseTransaction;
    },
    collapsePlatformClick() {
      this.isCollapsePlatform = !this.isCollapsePlatform;
    },
    async collapseDetailClick() {
      this.isCollapseDetail = !this.isCollapseDetail;
      this.getLabelList();
      this.getAgentList();
      this.getGroupList();
    },
    collapseNotesClick() {
      this.isCollapseNotes = !this.isCollapseNotes;
    },
    handlePreview(message) {
      this.selected_preview = message;
      this.showModalPreview = true;
    },
    handleMediaDetail(url, type) {
      this.url_media_detail = url;
      this.type_media_detail = type;
      this.showModalMediaDetail = true;
    },
    handleInputKey(event) {
      if ((event.keyCode === 10 || event.keyCode === 13) && (event.ctrlKey || event.metaKey)) {
        this.sendReply();
      }
    },
    addEmoji(emoji) {
      const pos = this.last_index_emoji !== null ? this.last_index_emoji : this.$refs.message_input.selectionStart;
      if (this.last_index_emoji === null) this.last_index_emoji = pos;
      const start_str = this.replyMessage.substring(0, pos);
      const end_str = this.replyMessage.substring(pos, this.replyMessage.length);
      // this.replyMessage += emoji.native;
      this.replyMessage = [start_str, emoji.native, end_str].join('');
    },
    async loadMoreMessages() {
      this.loading.moreMessages = true;
      this.pagination.messages += 1;
      await this.showDetailChat({
        conversation_id: this.conversation.id,
        reload_history: false,
      });
      this.loading.moreMessages = false;
    },
    resetInputReply() {
      this.disable_input = false;
      this.selected_filename = '';
      this.selectedFile = null;
      this.loading.send_reply = false;
      setTimeout(() => {
        this.$refs.message_input.focus();
      }, 10);
    },
    async sendReply() {
      // this.showLoader();
      let base64File = null;
      let messageType = 'text';
      if (!_.isEmpty(this.selectedFile) && this.selectedFile.file instanceof File) {
        base64File = await this.toBase64(this.selectedFile.file);
        const fileExt = mime.getExtension(this.selectedFile.file.type);
        if (['png', 'jpeg', 'jpg'].includes(fileExt)) {
          messageType = 'image';
        } else if (['mp4'].includes(fileExt)) {
          messageType = 'video';
        } else if (['doc', 'docx', 'xlsx', 'xls', 'pdf'].includes(fileExt)) {
          messageType = 'document';
        } else {
          this.$message({
            message: this.$t('general.error.file_not_supported'),
            type: 'warning',
            duration: 10 * 1000,
          });
          return;
        }
      } else if (!_.isEmpty(this.selectedFile) && typeof this.selectedFile === 'object' && this.selectedFile.file.url.substr(0, 4) === 'http') {
        messageType = this.selectedFile.type;
        base64File = this.selectedFile.file.url;
      }
      this.loading.send_reply = true;
      const msg = this.replyMessage;
      // if (messageType === 'document') {
      //   msg = this.selectedFile.file.name;
      // }
      const msg_to_send_lists = [];
      if (this.conversation.active_channel === 'instagram') {
        const temp_msg = _.cloneDeep(this.raw_msg);
        const temp_msg_text = _.cloneDeep(this.raw_msg);
        temp_msg.conversation_id = this.selected_conversation_id;
        temp_msg.body = messageType !== 'text' ? null : msg;
        if (messageType !== 'text') {
          temp_msg_text.body = msg;
          temp_msg_text.body_type = 'text';
          temp_msg_text._fe_index = `${moment().format('X')}_text`;
          delete temp_msg.caption;
          delete temp_msg.message;
        }
        temp_msg.body_type = messageType;
        temp_msg.is_loading = true;
        temp_msg.status = 'pending';
        temp_msg.formattedTime = moment().format('HH:mm');
        temp_msg._fe_index = moment().format('X');

        msg_to_send_lists.push(temp_msg_text);
        msg_to_send_lists.push(temp_msg);
        // ignore
      } else {
        const temp_msg = _.cloneDeep(this.raw_msg);
        temp_msg.conversation_id = this.selected_conversation_id;
        temp_msg.body = messageType !== 'text' && messageType !== 'document' ? base64File : msg;
        if (messageType === 'document') {
          temp_msg.caption = this.selectedFile.file.name;
        } else if (messageType !== 'text') {
          temp_msg.caption = msg;
        }
        temp_msg.body_type = messageType;
        temp_msg.is_loading = true;
        temp_msg.status = 'pending';
        temp_msg.formattedTime = moment().format('HH:mm');
        msg_to_send_lists.push(temp_msg);
        temp_msg._fe_index = moment().format('X');
      }
      let res_data = [];
      const replyContext = this.replyMessageContext;
      msg_to_send_lists.forEach((temp_msg) => {
        if (!temp_msg.body && temp_msg.body_type === 'text') {
          this.resetInputReply();
          return;
        }
        // console.log(temp_msg);
        this.messages.push(temp_msg);

        // After messages updated
        this.updateLastMessage(temp_msg);
        this.replyMessage = '';
        this.clearSelectedFile();

        this.resetInputReply();
        // this.hideLoader();
        this.scrollChatToBottom();
        // if (!msg) {
        //   this.$message({
        //     message: 'Failed sent message. Please refresh page.',
        //     type: 'warning',
        //   });
        //   return;
        // }
        if (this.conversation.id !== this.selected_conversation_id) {
          this.$message({
            message: 'Failed sent message. Please refresh page.',
            type: 'warning',
          });
          return;
        }
        conversationsAPI.replyMessage({
          text: temp_msg.body,
          message: msg,
          workspace_id: this.activeWorkspace._id,
          conversation_id: this.conversation.id,
          type: this.reply_type === 'reply' ? (temp_msg.body_type) : 'private_note',
          base64: base64File,
          caption: ['image', 'video', 'document'].includes(temp_msg.body_type) ? temp_msg.caption : '',
          msg_id: this.replyMessageContext !== null ? this.replyMessageContext.msg_id : null,
          reply_id: this.replyMessageContext !== null ? this.replyMessageContext.id : null,
        }).then((res) => {
          if (res.error) {
            const error_message = res.error.message;
            this.$message({
              message: `Failed. ${error_message}`,
              type: 'error',
            });
            return;
          }
          this.replyMessageContext = null;
          const { message } = res.data;
          res_data = res.data.message;
          const mappedMessage = message;
          // console.log('Done send message', mappedMessage);
          if (message.conversation_id === this.selected_conversation_id) {
            const index = this.messages.findIndex((v) => v._fe_index === temp_msg._fe_index);
            if (index > -1) {
              this.$set(this.messages, index, this.constructMessage(mappedMessage));
            } else {
              this.messages.push(this.constructMessage(mappedMessage));
              const indexZero = this.messages.findIndex((v) => v.id === 0);
              this.messages = _.uniqBy(this.messages, 'id');
              this.messages.splice(indexZero, 0);
            }
          }
          if (_.isEmpty(this.replyContext?.msg_id) && !_.isEmpty(res_data.msg_id)) {
            this.showDetailChatHistory({ conversation_id: this.conversation.id });
          }
          this.updateLastMessage(mappedMessage);
          this.refreshConvoDetail(this.conversation.id);
        });
        this.messages = _.uniqBy(this.messages, 'id');
      });
      if (this.conversation.status === 'open' && this.selected_conversation_status === this.conversation.status) {
        await this.loadConversations();
        this.conversations.splice(this.conversations.findIndex((v) => v.id === this.conversation.id), 1);
        this.conversation.status = 'handling';
        this.status_count.handling += 1;
        this.status_count.open -= 1;
      }

      if (_.isEmpty(replyContext?.msg_id) && !_.isEmpty(res_data.msg_id)) {
        this.showDetailChatHistory({ conversation_id: this.conversation.id });
      }
    },
    constructMessage(message) {
      const mappedMessage = message;
      const isToday = moment(message.updated_at).isSame(moment(), 'day');
      mappedMessage.formattedTime = moment(message.updated_at).format(isToday ? 'HH:mm' : 'D MMM YYYY HH:mm');
      mappedMessage.formattedType = message.type === 'in' ? 'friend' : 'me';
      if (message.type === 'out' && (['private_note', 'system'].includes(mappedMessage.body_type) || (_.isEmpty(message.user) && !message.user_id))) {
        mappedMessage.formattedType = 'system';
        if (mappedMessage.body_type === 'private_note') {
          mappedMessage.formattedType = 'private_note';
        }
      }
      if (!message.body_type) message.body_type = 'text';
      const channel_icon = this.channelList.find((v) => v.value === message.channel);
      message.channel_icon = `mdi mdi-${channel_icon?.icon}`;
      if (message.channel === 'whatsapp') {
        message.channel_name_str = 'WhatsApp';
        message.channel_color = 'text-success';
      } else if (message.channel === 'telegram') {
        message.channel_name_str = 'Telegram';
        message.channel_color = 'text-telegram';
      } else if (message.channel === 'webchat') {
        message.channel_name_str = 'Webchat';
        message.channel_color = 'text-default';
      } else if (message.channel === 'instagram') {
        message.channel_name_str = 'Instagram';
        message.channel_color = 'text-instagram';
      } else if (message.channel === 'fb_messenger') {
        message.channel_name_str = 'Facebook';
        message.channel_color = 'text-fb_messenger';
      } else if (message.channel === 'phone_call') {
        message.channel_name_str = 'Phone Call';
        message.channel_color = 'text-phone_call';
      } else {
        message.channel_name_str = 'Webchat';
        message.channel_color = 'text-default';
      }
      mappedMessage.is_loading = false;
      return mappedMessage;
    },
    scrollChatToBottom() {
      setTimeout(() => {
        const container = this.$el.querySelector('.chat-content .chat-body');
        container.scrollTop = container.scrollHeight;
        container.focus();
        this.perfectScroll[1].update();
      }, 0);
    },
    scrollToTop() {
      setTimeout(() => {
        this.perfectScroll[0].update();
      }, 0);
    },
    scrollToTopHistory() {
      setTimeout(() => {
        this.perfectScroll[2].update();
      }, 0);
    },
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    shoModalEditContact() {
      this.formEditContact = _.cloneDeep(this.conversation.selected_contact);
      this.modalEditContact = true;
    },
    shoModalMergeContact() {
      this.selectedContactToMerge = _.cloneDeep(this.conversation.selected_contact);
      this.modalMergeContact = true;
    },
    async updateContact() {
      this.v$.formEditContact.$touch();
      if (this.v$.formEditContact.$error) return;
      this.loading.update_contact = true;
      await contactApi.update({
        workspace_id: this.activeWorkspace._id,
        id: this.formEditContact.id,
        data: {
          display_name: this.formEditContact.name,
        },
      }).then(async (response) => {
        await popupErrorMessages(response);
        this.$message({
          type: 'success',
          message: this.$t('general.success'),
        });
        this.modalEditContact = false;
        this.showDetailChat({ conversation_id: this.conversation.id, reload_history: false });
      }).catch(() => {});
      this.loading.update_contact = false;
    },
    closeModalContact() {
      this.modalEditContact = false;
      this.formEditContact = {};
    },
    async showDetailChat({ conversation_id, reload_history = true }) {
      this.clearSelectedFile();
      // this.showDetailConversation = true;
      if (this.pagination.messages === 1) {
        this.isCollapseHistory = false;
        this.isCollapseMedia = false;
        this.isCollapseDetail = false;
        this.loadedConversation = false;
        this.messages = [];
        this.conversation = {};
        this.rows_pin = {};
        this.assigned_to = '';
        this.selected_conversation_id = conversation_id;
      }

      this.loadedConversation = true;
      this.loading.chat_detail = true;
      await conversationsAPI.getListDetail(this.activeWorkspace._id, conversation_id, this.pagination.messages)
        .then(async (res) => {
          socket.emit('messages', conversation_id);
          this.conversation = res.data.conversation;
          if (this.conversation.status !== 'resolved') this.getCallRequestStatus(conversation_id);
          this.rows_pin = res.data.rows_pin;
          this.conversations.contacts = res.data.conversation.contacts.map((v) => {
            const ch = this.channelList.find((c) => c.value === v.channel);
            v.icon = ch.icon;
            v.color = `text-${ch.value}`;
            return v;
          });
          // const selected_contact = this.conversation.contacts.find((v) => v.channel === this.conversation.active_channel);
          const parent_contact = this.conversation.contacts.find((v) => !_.isEmpty(v.child_ids) || v.status === 'active');
          this.conversation.selected_contact = parent_contact;
          this.conversation.contactName = parent_contact ? parent_contact.name : '-';
          this.conversation.phoneNumber = parent_contact ? parent_contact.phone_number : '-';
          if (parent_contact && parent_contact.channel === 'instagram') {
            this.conversation.phoneNumber = parent_contact.original_name || '';
          }
          if (parent_contact && parent_contact.channel === 'webchat') {
            this.conversation.phoneNumber = '';
          }
          this.conversation.isContactBlocked = parent_contact ? parent_contact.is_blocked : false;
          this.conversation.contactId = parent_contact ? parent_contact.id : null;
          this.conversation.platform_used = res.data.conversation.channels.map((v) => this.channelList.find((ch) => ch.value === v));
          this.can_reply_message = res.data.can_reply_message;
          if (this.pagination.messages === 1) {
            this.messages = res.data.messages.map((message) => {
              const mappedMessage = message;
              return this.constructMessage(mappedMessage);
            }).reverse();
          } else if (this.pagination.messages > 1) {
            const msg = res.data.messages.map((message) => {
              const mappedMessage = message;
              return this.constructMessage(mappedMessage);
            }).reverse();
            this.messages.unshift(...msg);
          }
          if (this.messages.length >= res.data.total_messages) {
            this.max_page.messages = true;
          } else {
            this.max_page.messages = false;
          }
          this.$refs.message_input.focus();
          if (reload_history) {
            this.conversationHistoryList = [];
          }
          this.assigned_to = this.conversation.agent ? this.conversation.agent.id : '';
          this.assigned_group_to = this.conversation.agent_group ? this.conversation.agent_group.id : '';
          this.selected_label = this.conversation.labels.map((v) => v.id) || [];
          if (this.assigned_to) {
            const agent = this.agentList.find((v) => v.id === this.assigned_to);
            if (agent === undefined) {
              const obj = this.conversation.agent;
              obj.status = 'deleted';
              this.agentList.push(obj);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.loading.chat_detail = false;
      this.showDetail = true;
      if (this.pagination.messages === 1) {
        this.scrollChatToBottom();
      }
    },
    hideDetailChat() {
      this.showDetail = false;
    },
    handlerFilterLabelChange(value) {
      if (this.searchForm.search_by === 'label' && value) {
        this.loadConversations({ page: 1 });
      }
    },
    async loadConversations({ page } = { page: 1 }) {
      // console.log('page', page);
      // if (this.searchForm.assigned_to === 'bot' || (page === 1 || page === undefined)) {
      if ((page === 1 || page === undefined)) {
        this.pagination.page = 1;
        this.conversations = [];
      }
      // this.replyMessage = '';
      this.loading.chat_list = true;
      await conversationsAPI.getList({
        workspace_id: this.activeWorkspace._id,
        status: this.selected_conversation_status,
        assigned_to: this.searchForm.assigned_to,
        page,
        search: this.searchForm.search,
        search_by: this.searchForm.search_by,
        label_id: this.searchForm.search_by === 'label' ? this.searchForm.label_id : this.urlParams.label_id,
        sort_by: this.searchForm.sort_by.split(':')[0],
        sort_type: this.searchForm.sort_by.split(':')[1],
      }).then((res) => {
        res.data.rows.forEach((v) => {
          if (!v.thumbnail_color) v.thumbnail_color = '#192A53';
          this.conversations.push(v);
        });
        this.conversations = _.uniqBy(this.conversations, 'id');
        if (res.data.rows.length === 0 || this.conversations.length === res.data.count) this.isMoreResult = false;
        else this.isMoreResult = true;
        this.busy = false;
        this.status_count = res.data.status_count;
      })
        .catch((err) => { console.log(err); });
      this.loading.chat_list = false;
    },
    resetListConversation(closeDetail = true) {
      if (closeDetail) {
        this.conversation = {};
        this.rows_pin = {};
        this.showDetail = false;
        this.loadedConversation = false;
        this.showDetailConversation = false;
      }
      setTimeout(() => {
        this.loadConversations({ page: 1 });
      }, 500);
    },
    doConnectSocket() {
      if (this.$store.state.auth.userProfile.agents[0]) {
        const agent_id = this.$store.state.auth.userProfile.agents[0].id;
        if (!this.reconnect) {
          socket.disconnect();
        }
        socket.connect();
        socket.emit('conversation', agent_id);
      }
      if (this.selected_conversation_id) {
        socket.emit('messages', this.selected_conversation_id);
      }
    },
    tabStatusConvoClicked(tabName = 'open') {
      this.isMoreResult = true;
      this.pagination.page = 1;
      this.selected_conversation_status = tabName;
      this.conversations = [];
      this.loadConversations();
      this.scrollToTop();
    },
    updateLastMessage(message) {
      const objIndex = this.conversations.findIndex((v) => v.id === message.conversation_id);
      if (objIndex > -1) {
        this.conversations[objIndex].last_msg = message;
        // this.conversations.unshift(this.conversations.splice(objIndex, 1)[0]);
        this.conversations.splice(this.rows_pin[this.selected_conversation_status], 0, this.conversations.splice(objIndex, 1)[0]);
      }
    },
    endConversation() {
      this.$confirm(this.$t('conversations.confirm.end_conversation'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            await conversationsAPI.endConversation({
              conversation_id: this.selected_conversation_id,
              workspace_id: this.activeWorkspace._id,
            }).then((response) => {
              if (response.error) {
                const error_message = response.error.message;
                this.$message({
                  message: `Failed. ${error_message}`,
                  type: 'error',
                });
                cb();
                return;
              }
              this.$message({
                message: this.$t('conversations.success.end_conversation'),
                type: 'success',
              });
              const objIndex = this.conversations.findIndex((v) => v.id === this.selected_conversation_id);
              this.conversations.splice(objIndex, 1);
              if (this.conversation.status === 'open') this.status_count.open -= 1;
              if (this.conversation.status === 'handling') this.status_count.handling -= 1;
              this.status_count.resolved += 1;
              this.messages = [];
              this.conversation = {};
              this.rows_pin = {};
              this.showDetailConversation = false;
              this.loadedConversation = false;
              this.selected_conversation_id = '';
              cb();
            }).catch(() => {});
            instance.confirmButtonLoading = false;
            return;
          }
          instance.confirmButtonLoading = false;
          cb();
        },
      });
    },
    async pinChat(pin_at) {
      let text_confirm = '';
      if (pin_at) {
        text_confirm = this.$t('conversations.confirm.unpin_chat');
      } else {
        text_confirm = this.$t('conversations.confirm.pin_chat');
      }
      this.$confirm(text_confirm, this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            await conversationsAPI.pinChat({
              conversation_id: this.selected_conversation_id,
              workspace_id: this.activeWorkspace._id,
            }).then((response) => {
              if (response.error) {
                const error_message = response.error.message;
                this.$message({
                  message: `Failed. ${error_message}`,
                  type: 'error',
                });
                cb();
                return;
              }
              this.$message({
                message: this.$t('conversations.success.pin_chat'),
                type: 'success',
              });
              this.loadConversations();
              this.showDetailChatHistory({ conversation_id: this.selected_conversation_id });
              cb();
            }).catch(() => {});
            instance.confirmButtonLoading = false;
            return;
          }
          instance.confirmButtonLoading = false;
          cb();
        },
      });
    },
    async conversationHistory(contact_id) {
      this.loading.history = true;
      await conversationsAPI.getConversationHistory(this.activeWorkspace._id, contact_id)
        .then(async (response) => {
          await popupErrorMessages(response);
          response.data.rows = response.data.rows.map((v) => {
            const isToday = moment(v.resolved_at).isSame(moment(), 'day');
            v.formattedTime = moment(v.resolved_at).format(isToday ? 'HH:mm' : 'D MMM YYYY HH:mm');
            if (!v.thumbnail_color) v.thumbnail_color = '#192A53';
            return v;
          });
          this.conversationHistoryList.push(...response.data.rows);
          if (this.conversationHistoryList.length >= response.data.count) {
            this.isMoreResultHistory = false;
          }
        })
        .catch(() => {});
      this.loading.history = false;
      // this.scrollToTopHistory();
    },
    async conversationMedia(conversation_id) {
      this.loading.media = true;
      await conversationsAPI.getConversationMedia(this.activeWorkspace._id, conversation_id, this.pagination_media.page)
        .then(async (response) => {
          await popupErrorMessages(response);
          console.log(response);
          response.data.rows = response.data.rows.map((v) => {
            const isToday = moment(v.created_at).isSame(moment(), 'day');
            v.created_at_str = moment(v.created_at).format(isToday ? 'HH:mm' : 'D MMM YYYY HH:mm');
            if (!v.thumbnail_color) v.thumbnail_color = '#192A53';
            return v;
          });
          this.conversationMediaList.push(...response.data.rows);
          if (this.conversationMediaList.length >= response.data.count) {
            this.isMoreResultMedia = false;
          } else {
            this.isMoreResultMedia = true;
          }
        })
        .catch(() => {});
      this.loading.media = false;
    },
    async conversationLeads(page, contact_id) {
      console.log(page);
      this.loading.leads = true;
      await leadsAPI.get_completed_leads(this.activeWorkspace._id, { page, contact_id })
        .then(async (response) => {
          await popupErrorMessages(response);
          response.data.rows = response.data.rows.map((v) => {
            const isToday = moment(v.resolved_at).isSame(moment(), 'day');
            v.formattedTime = moment(v.completed_at).format(isToday ? 'HH:mm' : 'D MMM YYYY HH:mm');
            if (!v.thumbnail_color) v.thumbnail_color = '#192A53';
            return v;
          });
          this.leadsPage = page;
          this.isMoreResultLeads = false;
          this.conversationLeadsList.push(...response.data.rows);
          if (this.conversationLeadsList.length < response.data.total) {
            this.isMoreResultLeads = true;
          }
        })
        .catch(() => {});
      this.loading.leads = false;
      // this.scrollToTopHistory();
    },
    showDetailChatHistory({ conversation_id }) {
      this.showDetailChat({
        conversation_id,
        reload_history: false,
      });
    },
    showDetailLeadHistory(lead_id) {
      this.$router.push(`/leads/card/${lead_id}`);
    },
    async infiniteHandler() {
      this.pagination.page += 1;
      this.loading.load_more = true;
      await this.loadConversations({
        page: this.pagination.page,
      });
      this.loading.load_more = false;
    },
    async infiniteHandlerMedia() {
      this.pagination_media.page += 1;
      this.loading.load_more = true;
      await this.conversationMedia(this.conversation.id);
      this.loading.load_more = false;
    },
    onScrollListConversations(e) {
      if (Math.ceil(e.srcElement.offsetHeight + e.srcElement.scrollTop) >= e.srcElement.scrollHeight) {
        if (this.isMoreResult && !this.loading.load_more) {
          this.infiniteHandler();
        }
      }
    },
    handlerSearchUser(value) {
      clearTimeout(this.timeout_search_user);
      this.timeout_search_user = setTimeout(() => {
        this.getAgentList(value);
      }, 1000);
    },
    async getAgentList(search = '') {
      this.loading.agent = true;
      await agentsAPI.getList(this.activeWorkspace._id, this.page, search, this.conversation.assigned_group_id)
        .then(async (res) => {
          await popupErrorMessages(res);
          this.agentList = res.data.rows;
        })
        .catch(() => {});
      this.loading.agent = false;
    },
    handlerSearchAgentGroup(value) {
      clearTimeout(this.timeout_search_agent_group);
      this.timeout_search_agent_group = setTimeout(() => {
        this.getGroupList(value);
      }, 1000);
    },
    async getGroupList(search = '') {
      this.loading.group = true;
      await groupsAPI.getList(this.activeWorkspace._id, 1, search)
        .then((res) => {
          this.groupList = res.data.rows;
        })
        .catch(() => {});
      this.loading.group = false;
    },
    async reassignAgent() {
      await this.$confirm(this.$t('conversations.confirm.reassign_agent'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            const response = await conversationsAPI.reassignTicketAgent({
              workspace_id: this.activeWorkspace._id,
              conversation_id: this.conversation.id,
              recipient: this.assigned_to,
            });
            instance.confirmButtonLoading = false;
            await popupErrorMessages(response);
            this.$message({
              type: 'success',
              message: this.$t('conversations.success.reassign_agent'),
            });
            this.showDetailChat({ conversation_id: this.conversation.id });
            cb();
            return;
          }
          this.assigned_to = this.conversation.agent.id;
          cb();
        },
      }).catch(() => {});
    },
    async reassignAgentGroup() {
      await this.$confirm(this.$t('conversations.confirm.reassign_agent_group'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            const response = await conversationsAPI.reassignTicketAgent({
              workspace_id: this.activeWorkspace._id,
              conversation_id: this.conversation.id,
              recipient: this.assigned_group_to,
              recipient_type: 'group',
            });
            instance.confirmButtonLoading = false;
            await popupErrorMessages(response);
            this.$message({
              type: 'success',
              message: this.$t('conversations.success.reassign_agent_group'),
            });
            this.showDetailChat({ conversation_id: this.conversation.id });
            cb();
            return;
          }
          this.assigned_group_to = this.conversation.agent_group.id;
          cb();
        },
      }).catch(() => {});
    },
    isFromAgent(message) {
      return message.type === 'out' && message.body_type !== 'system' && message.user?.name;
    },
    initials(string) {
      return (string || '').split(' ').map((n) => n[0]).join('').slice(0, 2);
    },
    handleSendFile(object) {
      this.selectedFile = object;
      this.selected_filename = object.file.name;
      // const fileExt = mime.getExtension(object.file.type);
      this.disable_input = false;
      // if (['doc', 'docx', 'xlsx', 'xls', 'pdf'].includes(fileExt) && ['whatsapp'].includes(this.conversation.active_channel)) {
      //   this.disable_input = true;
      // }
    },
    clearSelectedFile() {
      this.selectedFile = {};
      this.selected_filename = '';
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async getLabelList() {
      this.loading.label = true;
      await labeslApi.getList(this.activeWorkspace._id)
        .then((response) => {
          this.labelList = response.data.rows;
        });
      this.loading.label = false;
    },
    showInputNewLabel() {
      this.showInputLabel = true;
      this.$nextTick(() => {
        this.$refs.select_new_tag.focus();
      });
    },
    selectedLabelChanged(value) {
      this.showInputLabel = false;
      const current_label = this.conversation.labels.map((v) => v.id);
      let changed_labels = _.difference(value, current_label);
      let action = 'add';
      if (current_label.length > value.length) {
        changed_labels = _.difference(current_label, value);
        action = 'remove';
      } else if (current_label.length === value.length) {
        return;
      }
      // console.log(changed_labels, action);
      this.updateLabel(changed_labels[0], action);
    },
    async updateLabel(label_id, action) {
      this.loading.update_label = true;
      await conversationsAPI.updateLabel({
        workspace_id: this.activeWorkspace._id,
        conversation_id: this.conversation.id,
        label_id,
        action,
      }).then(async (response) => {
        await popupErrorMessages(response);
        this.showDetailChat({
          conversation_id: this.conversation.id,
          reload_history: false,
        });
      }).catch(() => {});
      this.loading.update_label = false;
    },
    tagLabelClosed(label_id) {
      if (['unhandled', 'resolved'].includes(this.conversation.status)) return;
      this.updateLabel(label_id, 'remove');
    },
    async loadQrList() {
      this.loading.qrlist = true;
      const response = await qrApi.getList({
        workspace_id: this.activeWorkspace._id,
        page: this.page.qr,
      }).catch(() => {});
      this.loading.qrlist = false;
      await popupErrorMessages(response);
      this.qrList = response.data.rows;
      this.totalRow.qr = response.data.total;
    },
    appendQr(obj) {
      const { body_type, body, caption } = obj;
      this.disable_input = false;
      if (body_type === 'text') {
        this.replyMessage += ` ${body.replace(/{{cs_name}}|{{user_name}}|{{agent_name}}/ig, this.userProfile.name)}`;
      } else if (['image', 'video'].includes(body_type)) {
        this.replyMessage += ` ${caption.replace(/{{cs_name}}|{{user_name}}|{{agent_name}}/ig, this.userProfile.name)}`;
        const splitString = body.split('/');
        this.selectedFile = {
          type: body_type,
          file: {
            url: body,
            name: caption,
          },
        };
        this.selected_filename = splitString[splitString.length - 1];
      } else if (body_type === 'document') {
        this.replyMessage = '';
        const splitString = body.split('/');
        this.selected_filename = splitString[splitString.length - 1];
        this.selectedFile = {
          type: body_type,
          file: {
            url: body,
            name: this.selected_filename,
          },
        };
        // this.disable_input = true;
      }
      this.replyMessage = this.replyMessage.trim();
      this.showModalQr = false;
      setTimeout(() => {
        this.$refs.message_input.focus();
      }, 500);
    },
    isNearExpired(difference) {
      // console.log(difference);
      if (difference < 1440 && difference > 1080) {
        return { 'text-warning': true, 'text-muted': false, 'text-body': false };
      }
      if (difference > 1440) {
        return { 'text-danger': true, 'text-muted': false, 'text-body': false };
      }
      return {};
    },
    searchByChanged() {
      if (this.searchForm.search_by === 'label') {
        this.isVisibleLabelFilter = true;
        if (this.searchForm.label_id) {
          this.searchConversation();
        }
        return;
      }
      // this.searchForm.search = '';
      this.isVisibleLabelFilter = false;
      this.searchConversation();
    },
    searchConversation() {
      this.conversations = [];
      this.loadConversations({ page: 1 });
    },
    handlerSearchInput(event) {
      if (event.keyCode === 13 || event.code === 'Enter' || event.key === 'Enter') {
        this.searchConversation();
      }
    },
    async loadProductList() {
      this.loading.productList = true;
      const response = await productApi.getList({
        workspace_id: this.activeWorkspace._id,
        page: this.page.product,
        // search_keyword: this.search_keyword,
      });
      this.loading.productList = false;
      await popupErrorMessages(response);
      // console.log(response.data);
      this.totalRow.product = response.data.count;
      this.productList = response.data.rows.map((v) => {
        v.thumbnail = this.placeholderImage;
        v.thumbnail_type = 'image';
        if (v.product_attachments[0]) {
          const ath = v.product_attachments[0];
          v.thumbnail = ath.full_url;
          v.thumbnail_type = ath.mime_type.split('/')[0];
        }
        return v;
      });
    },
    appendProduct(obj) {
      const {
        name, price, description, thumbnail,
      } = obj;
      this.disable_input = false;
      this.replyMessage = `Produk: *${name}*\nHarga: *Rp. ${price.toLocaleString()}*\n\n${description}`.trim();
      const splitString = thumbnail.split('/');
      this.selectedFile = {
        type: 'image',
        file: {
          url: thumbnail,
          name,
        },
      };
      this.selected_filename = splitString[splitString.length - 1];
      // this.replyMessage = this.replyMessage.trim();
      this.showModalProduct = false;
      setTimeout(() => {
        this.$refs.message_input.focus();
      }, 500);
    },
    afterSubmitTrx(url) {
      this.showModalCreateTrx = false;
      this.replyMessage = `Pay here: ${url}`;
      navigator.clipboard.writeText(url).then(() => {
        this.$message({
          message: 'URL copied',
          type: 'success',
        });
      }, (err) => {
        console.log(err);
      });
      this.$refs.message_input.focus();
    },
    parseHtmlAnchor(string) {
      const rxCommonMarkLink = /(\[([^\]]+)])\(([^)]+)\)/g;
      return string ? string.replace(rxCommonMarkLink, '$2') : string;
    },
    identicons(value) {
      return toSvg(value, '40');
    },
    handlerTakeOverBtn() {
      this.$confirm(this.$t('conversations.takeover_unhandler.confirmation'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'info',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            const response = await conversationsAPI.takeOverUnhandled({
              workspace_id: this.activeWorkspace._id,
              conversation_id: this.conversation.id,
            }).catch(() => {});
            try {
              await popupErrorMessages(response);
              this.$message({
                message: this.$t('conversations.takeover_unhandler.success'),
                type: 'success',
              });
              this.messages = [];
              this.conversation = {};
              this.showDetailConversation = false;
              this.loadedConversation = false;
              this.selected_conversation_id = '';
              const removeIndex = this.conversations.findIndex((v) => v.id === this.conversation.id);
              this.conversations.splice(removeIndex, 1);
              this.loadConversations({ page: this.pagination.page });
            } catch (error) {
              console.log(error);
            }
            instance.confirmButtonLoading = false;
          }
          done();
        },
      });
    },
    handlerClickConvoList(conversation_id) {
      this.pagination.messages = 1;
      this.showDetailChat({ conversation_id });
    },
    async handlerBtnBlockUnblock(action, id) {
      if (action === 'block') {
        await this.blockContact(id);
      }
      if (action === 'unblock') {
        await this.unblockContact(id);
      }
    },
    async blockContact(id) {
      this.$prompt('Are you sure to block this contact? You will no longer receive message from this contact.', 'Block this contact?', {
        inputPlaceholder: 'Write reason why you block this contact',
        type: 'warning',
        center: true,
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
      }).then(async ({ value }) => {
        this.blocked_reason = value;
        await contactApi.blockContact({
          contact_id: id,
          blocked_reason: this.blocked_reason,
        }).then((resp) => {
          if (!resp.error) {
            // to reset list contact
            this.showDetailChat({ conversation_id: this.conversation.id, reload_history: false });
            this.$message({
              type: 'success',
              message: 'Successfully block contact',
            });
          } else {
            this.$message({
              type: 'error',
              message: resp.error.message || 'Something wrong, please try again.',
            });
          }
        }).catch((err) => {
          console.log(err);
          this.$message({
            type: 'error',
            message: 'Something wrong, please try again.',
          });
        });
      }).catch(() => {});
    },
    async unblockContact(id) {
      this.$confirm('Are you sure to unblock this contact?', 'Unlock this contact?', {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
      }).then(async () => {
        await contactApi.unblockContact({
          contact_id: id,
        }).then((resp) => {
          if (!resp.error) {
            // to reset list contact
            this.showDetailChat({ conversation_id: this.conversation.id, reload_history: false });
            this.$message({
              type: 'success',
              message: 'Successfully unblock contact',
            });
          } else {
            this.$message({
              type: 'error',
              message: resp.error.message || 'Something wrong, please try again.',
            });
          }
        }).catch((err) => {
          console.log(err);
          this.$message({
            type: 'error',
            message: 'Something wrong, please try again.',
          });
        });
      }).catch(() => {});
    },
    goToQuotedMessage(id, context_message) {
      const target = `message-${id}`;
      const element = document.getElementById(target);
      if (element) {
        setTimeout(() => {
          const container = document.querySelector('.chat-content .chat-body');
          const containerRect = container.getBoundingClientRect();
          const elementRect = element.getBoundingClientRect();
          const offsetTop = elementRect.top - containerRect.top + container.scrollTop;
          container.scrollTop = offsetTop;
          container.focus();
          this.perfectScroll[1].update();
        }, 0);
      } else {
        // popup
        this.showModalPreviewQuoted = true;
        this.selectedQuotedMessage = this.constructMessage(context_message);
      }
      this.replyMessageContext = null;
    },
    replyChat(context_message) {
      this.replyMessageContext = context_message;
      const container = this.$el.querySelector('.chat-footer');
      window.scrollTo(0, container.scrollHeight);
      setTimeout(() => {
        this.$refs.message_input.focus();
      }, 10);
    },
    closeReplyChat() {
      const element = this.replyMessageContext;
      if (element) {
        this.goToQuotedMessage(element.id, element);
      }
    },
    async handlerBtnSaveNotes() {
      try {
        this.loading.update_notes = true;
        const response = await conversationsAPI.updateNotes({
          workspace_id: this.activeWorkspace._id,
          conversation_id: this.conversation.id,
          data: {
            notes: this.conversation.notes,
          },
        });
        this.loading.update_notes = false;
        await popupErrorMessages(response);
        this.$message({
          type: 'success',
          message: 'Notes updated',
        });
      } catch (error) {
        console.log(error);
      }
    },
    async handleCall() {
      // this.button_call_active = true;
      if (this.buttonCallActive) {
        this.$confirm(this.$t('conversations.confirm.phone_call'), this.$t('general.confirmation'), {
          confirmButtonText: this.$t('general.yes'),
          cancelButtonText: this.$t('general.no'),
          type: 'warning',
          center: true,
          /* eslint-disable no-param-reassign */
          beforeClose: async (action, instance, cb) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              this.showModalPhoneCall = true;
              instance.confirmButtonLoading = false;
              this.outgoingCall(this.conversation);
            }
            cb();
          },
        });
      }
    },
    // handleEndIncomingCall() {
    //   socket_sip.terminateSessions();
    //   this.showModalIncomingCall = false;
    // },
    // handleStatusIncomingCall(status) {
    //   this.phone_call.status = status;
    // },
  },
  validations() {
    return {
      formEditContact: {
        name: {
          required,
        },
      },
    };
  },
};
</script>
<style>
[data-initials]:before {
  background: #8CC8ED;
  color: white;
  opacity: 1;
  content: attr(data-initials);
  display: inline-block;
  font-weight: bold;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 0.5em;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
[data-initials-ticket] {
  --initial-ticket-color: #192A53;
}
[data-initials-ticket]:before {
  /* background: #192A53; */
  background-color: var(--initial-ticket-color);
  font-size: 8px;
  color: white;
  opacity: 1;
  content: attr(data-initials-ticket);
  display: inline-block;
  font-weight: bold;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 1em;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.identicons {
  vertical-align: top;
  margin-right: 1em;
}
/*quoted message*/
.message-item .content{
  position: relative;
}
.quoted-message{
  width: auto;
  display: inline-block;
  background: #c9fcc7;
  color: rgba(24,41,83,0.7);
  padding: 8px 15px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  position: relative;
  z-index: 2;
  margin-bottom: -10px;
  transform: translateY(-20px);
  -moz-transform: translateY(-20px);
  -webkit-transform: translateY(-20px);
}
.quoted-message:hover {
  color: rgba(24,41,83,0.7);
}
.quoted-reply-message{
  width: auto;
  display: inline-block;
  background: #ffffff;
  color: rgba(65, 65, 65, 0.7);
  padding: 8px 25px;
  border-radius: 4px;
  border-style: solid;
  border-color: rgba(65, 65, 65, 0.7);
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  position: relative;
  z-index: 2;
  margin-top: 5px;
  transform: translateY(-20px);
  -moz-transform: translateY(-20px);
  -webkit-transform: translateY(-20px);
}
.quoted-reply-message:hover {
  color: rgba(24,41,83,0.7);
}
.message-item.me{
  text-align: left;
}
.message.has-quote{
  margin-top: 16px;
}

/* reply area */
.input-group input.private,
.input-group textarea.private,
.nav-tabs .nav-link.active#private-tab{
  background: #FBFBF3;
}

.chat-footer .search-form .input-group{
  /* height: 45px !important; */
  position: relative;
}

/* textarea#chatForm,
textarea#privateForm{
  padding: 12px 20px 12px 30px;
  height: 40px;
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
} */

textarea#chatForm{
  padding: 12px 20px 12px 30px;
  height: 40px;
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}
textarea#privateForm{
  padding: 12px 20px 12px 30px;
  height: 55px;
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.pr{
  position: relative;
}

.status-read{
  color: rgb(52, 183, 241);
}
.inbound-text{
  color: rgb(37, 211, 102)
}

/* Product */
.thumbnail-product {
  background-color: #ccd0d5;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  border-radius: 4px;
  box-sizing: border-box;
  /* width: 240px; */
}

.thumbnail-product::after {
  content: '';
  display: block;
  padding-top: calc(100% * (1 / 1.91));
}
</style>
<style lang="scss">
.button-action-system {
  margin-right: -50px !important;
  // margin-left: -20px !important;
  text-align: right !important;
}
.messages .message-item {
  &.system {
    margin: auto;
    .content {
      order: 1;
      margin: auto !important;
      // margin-left: -20px !important;
      color: #212529;
      .bubble {
        background: #e7e7e7;
        border-radius: 5px;
        margin: auto;
        align-self: center;
        p {
          text-align: center;
        }
      }
      span {
        text-align: center;
        display: block;
      }
    }
  }
}

.messages .message-item {
  &.private_note {
    margin: auto;
    .content {
      order: 1;
      margin: auto !important;
      color: #fff;
      .bubble {
        background: #fff3d5;
        color: #000;
        border-radius: 5px;
        margin: auto;
        align-self: center;
        p {
          text-align: center;
        }
      }
      span {
        text-align: center;
        display: block;
      }
    }
  }
}
ul .message-action {
  display: flex;
  list-style: none;
  padding: 0;
}

.callout {
  padding: 10px;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 5px;
  background:rgba(24,41,83,0.1);
  h4 {
    margin-top: 0;
    margin-bottom: 5px;
  }
  p:last-child {
    margin-bottom: 0;
  }
  code {
    border-radius: 3px;
  }
  & + .bs-callout {
    margin-top: -5px;
  }
}
@each $name,$color in (default,#777),(primary,#428bca),(success,#5cb85c),(danger,#d9534f),(warning,#f0ad4e),(info,#5bc0de), (bdc,#29527a) {
  .callout-#{$name} {
    border-left-color: $color;
    h4 {
      color: $color;
    }
  }
}

.bg-dark-incomingcall {
  background-color: #101623 !important;
}
.modal-content-incomingcall {
  border: 2px solid #474747;
  background-color: #101623;
  border-radius: 1.135rem;
}
.modal-header-incomingcall {
  /* border-bottom: 1px solid #474747; */
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
.modal-body-incomingcall {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
.border-header-incomingcall {
  border-bottom: 2px solid #474747 !important;
}
</style>
